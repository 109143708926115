<template>
  <b-form-group
    label="Результати оцінки фінансового стану"
    label-size="lg"
    label-class="font-weight-bold"
  >
    <b-table
      :fields="tableFields"
      :hover="true"
      :outlined="true"
      :fixed="true"
      :small="true"
      :items="financialAssessments"
    >
      <template
        v-slot:cell(actions)="data"
        v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
      >
        <b-button
          variant="link text-danger"
          size="sm"
          @click="removeFinancialAssessment(data.index)"
        >
          <i class="fa fa-close" />
        </b-button>
      </template>
    </b-table>

    <b-button
      v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
      variant="link text-success"
      size="sm"
      class="mb-2"
      @click="showFinancialAssessmentModel()"
    >
      <i class="fa fa-plus" />
    </b-button>
    <b-modal id="modalFinancialAssessment" hide-header hide-footer size="lg">
      <FinancialAssessmentForm />
    </b-modal>
  </b-form-group>
</template>

<script>
import FinancialAssessmentForm from "./FinancialAssessmentForm";
export default {
  components: {
    FinancialAssessmentForm
  },
  name: "FinancialAssessment",
  data() {
    return {
      tableFields: [
        { key: "date", label: "Дата оцінки фінансового стану" },
        { key: "rating", label: "Оцінка фінансового стану" },
        { key: "comment", label: "Комментар" },
        this.$auth.can("create counterpart")
          ? { key: "actions", label: "Дії" }
          : null
      ]
    };
  },
  computed: {
    financialAssessments: function() {
      return this.$store.getters["counterpart/getFinancialAssessments"];
    }
  },
  methods: {
    showFinancialAssessmentModel() {
      this.$root.$emit("bv::show::modal", "modalFinancialAssessment");
    },
    removeFinancialAssessment(index) {
      if (this.financialAssessments[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch(
            "counterpart/deleteFinancialAssessment",
            this.financialAssessments[index]
          );
      }
    }
  }
};
</script>
