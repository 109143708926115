<template>
  <b-modal
    id="create-verification-modal"
    title="Додати метод верифікації"
    hide-footer
    @show="refreshModal()"
    @hidden="refreshModal()"
  >
    <error-alert
      :errors="errors"
      message="Виникла помилка при додаванні верифікації!"
    />
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          name="спосіб проведення верифікації"
          v-slot="{ errors, dirty }"
          :rules="{ required: true }"
        >
          <b-form-group label="Спосіб проведення верифікації">
            <b-form-select
              id="verificationMethod"
              v-model="form.method_id"
              :options="filteredVerificationMethods"
              value-field="id"
              text-field="name"
              :state="setValidationState(errors, dirty)"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="дата проведення верифікації"
          :rules="{ required: true }"
        >
          <b-form-group label="Дата проведення верифікації">
            <b-form-input
              type="date"
              :max="moment().format('YYYY-MM-DD')"
              v-model="form.verified_at"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <div slot="footer">
          <b-button
            size="sm"
            type="submit"
            variant="success"
            :disabled="submitting"
          >
            Додати
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import mixins from "@/mixins";
import { mapGetters } from "vuex";
import ErrorAlert from "@/components/ErrorAlert.vue";
import moment from "moment/moment";

export default {
  name: "CreateVerification",
  components: { ErrorAlert },
  mixins: [mixins],
  data() {
    return {
      form: {
        method_id: null,
        verified_at: null,
      },
      errors: {},
      submitting: false,
    };
  },
  computed: {
    moment() {
      return moment
    },
    ...mapGetters({
      verificationMethods: "dictionary/allCounterpartVerificationMethods",
    }),
    filteredVerificationMethods() {
      return this.verificationMethods.filter(method => method.value !== 'icei');
    }
  },
  methods: {
    refreshModal() {
      this.form.method_id = null;
      this.form.verified_at = null;
      this.errors = {};
    },
    onSubmit() {
      this.submitting = true;
      this.errors = {};
      this.form.counterpart_id = this.$route.params.id;
      this.$store
        .dispatch("counterpart/createVerification", this.form)
        .then(() => {
          this.submitting = false;
          this.$bvModal.hide("create-verification-modal");
        })
        .catch(({ response }) => {
          this.submitting = false;
          this.$set(this, "errors", response.data);
        });
    },
  },
};
</script>
