import Vue from "vue";

const INTEGER_REGEXP = /^[0-9]+$/,
  // eslint-disable-next-line no-useless-escape
  DECIMAL_REGEXP_INPUT = /^(-)?\d+(\,){0,1}[0-9]{0,1}$/,
  // eslint-disable-next-line no-useless-escape
  DECIMAL_REGEXP = /^(-)?\d+(\,){0,1}[0-9]{0,2}$/;

Vue.directive("decimal", {
  bind(el, binding, vnode) {
    el.addEventListener("keypress", e => {
      const keyValue = e.key
        ? e.key
        : e.keyCode
        ? String.fromCharCode(e.keyCode)
        : String.fromCharCode(e.witch);
      const inputValue = el.value;
      const allowedKeys = [44, 45];

      //replace point to comma
      if (e.keyCode == 46 && DECIMAL_REGEXP_INPUT.test(inputValue)) {
        setTimeout(() => {
          const inputValue = el.value;
          el.value = inputValue.replace(".", ",");
          vnode.elm.dispatchEvent(new CustomEvent("input"));
        });
        return false;
      }

      if (
        document.getSelection().toString() == inputValue &&
        INTEGER_REGEXP.test(keyValue)
      ) {
        return true;
      }

      if (allowedKeys.includes(e.keyCode)) {
        if (
          !inputValue ||
          (inputValue.search(keyValue) === -1 &&
            DECIMAL_REGEXP.test(inputValue))
        ) {
          return true;
        } else {
          e.preventDefault();
        }
      }

      if (
        !INTEGER_REGEXP.test(keyValue) ||
        (inputValue.length > 1 && !DECIMAL_REGEXP_INPUT.test(inputValue))
      ) {
        e.preventDefault();
      }
    });

    el.addEventListener("paste", () => {
      setTimeout(() => {
        const inputValue = el.value;
        if (!DECIMAL_REGEXP.test(inputValue)) {
          el.value = "";
          vnode.elm.dispatchEvent(new CustomEvent("input"));
        }
      });
    });
  }
});

Vue.directive("currency", el => {
  setTimeout(() => {
    const value = parseFloat(el.value || 0);
    el.value = value.toFixed(2);
    el.value = el.value.replace(".", ",");
  });
});
