<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <div
          slot="header"
          class="d-flex align-items-center justify-content-between"
        >
          <strong v-if="jobsStateIs($stringConstants('PROGRESS_STATE'))">
            <b-spinner small type="grow"></b-spinner>
            Наповнюємо картку контрагента. Зачекайте...
          </strong>
          <strong v-else-if="jobsStateIs($stringConstants('ERROR_STATE'))">
            Помилка при наповнені картки контрагента
          </strong>
          <strong
            v-else-if="jobsStateIs($stringConstants('NEED_TO_ACTION_STATE'))"
          >
            Потрібно підтвердити знайдені зміни
          </strong>
          <strong v-else> Картка контрагента </strong>

          <div class="d-flex">
            <DownloadButtons :counterpart="counterpart" />
            <b-button
              class="ml-4 btn-decorate"
              @click="reloadCounterpart()"
              v-b-tooltip.hover
              title="Оновити картку контрагента"
            >
              <i class="fa fa-undo"></i>
            </b-button>
          </div>
        </div>
        <b-tabs>
          <b-tab title="Ідентифікаційні дані" active>
            <identifier-data
              :counterpart="counterpart"
              :load-counterpart="loadCounterpart"
            />
          </b-tab>
          <b-tab title="Вивчення клієнта">
            <client-discovering :counterpart="counterpart" />
          </b-tab>
          <b-tab title="Управління ризиками">
            <risk-management />
          </b-tab>
          <b-tab title="Оцінка відповідності">
            <conformity />
          </b-tab>
          <b-tab title="Ведення анкети">
            <polling />
          </b-tab>
          <b-tab
            title="Історія"
            v-if="
              $auth.can($stringConstants('PERMISSION_VIEW_COUNTERPART_HISTORY'))
            "
          >
            <history />
          </b-tab>
          <b-tab title="Протокол перевірок">
            <protocol :jobs="counterpart.jobs" />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import IdentifierData from "./parts/Identification";
import Polling from "./parts/Polling";
import Conformity from "./parts/Conformity";
import RiskManagement from "./parts/RiskManagement";
import ClientDiscovering from "./parts/ClientDiscovering";
import History from "./parts/History";
import Protocol from "./parts/Protocol";
import { mapGetters } from "vuex";
import DownloadButtons from "@/entity/Counterparts/components/DownloadButtons.vue";

export default {
  name: "CounterpartItem",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can("view counterpart")) next();
      else next({ name: "Page404" });
    });
  },
  data() {
    return {
      loadCounterpart: false,
    };
  },
  async created() {
    this.loadCounterpart = true;
    await this.$store.dispatch("counterpart/pull", this.$route.params.id);
    this.loadCounterpart = false;
  },
  components: {
    DownloadButtons,
    IdentifierData,
    Polling,
    Conformity,
    RiskManagement,
    ClientDiscovering,
    History,
    Protocol,
  },
  beforeDestroy() {
    this.$store.dispatch("counterpart/flush");
  },
  computed: {
    ...mapGetters({
      counterpart: "counterpart/get",
    }),
  },
  methods: {
    jobsStateIs(state) {
      return this.counterpart.jobs_state === state;
    },
    reloadCounterpart() {
      this.loadCounterpart = true;
      this.$store
        .dispatch("counterpart/pull", this.$route.params.id)
        .then(() => {
          this.$snotify.success("Дані контрагента оновлено");
          this.loadCounterpart = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-decorate {
  background-color: #6752b1 !important;
  border-color: #6752b1 !important;
  color: #ffffff !important;
  opacity: 1 !important;

  &:hover {
    background-color: #5d44b0;
    color: #ffffff;
  }

  &:focus {
    background-color: #5d44b0;
    color: #ffffff;
  }
}
</style>
