var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',{attrs:{"label":"Підрозділ","label-size":"lg","label-class":"font-weight-bold"}},[_c('ValidationProvider',{attrs:{"name":"назва підрозділу","rules":{
          required: true,
          regex: _vm.$stringConstants('REGEX_TEXT_NAME'),
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label":"Назва підрозділу"}},[_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_vm._l((errors),function(error,index){return _c('b-form-invalid-feedback',{key:index,attrs:{"state":errors.length === 0}},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"країна реєстрації","rules":"required","vid":"country_of_registration_id"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label":"Країна реєстрації"}},[_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null,"options":_vm.countryList,"text-field":"name.short-name","value-field":"id"},model:{value:(_vm.form.country_of_registration_id),callback:function ($$v) {_vm.$set(_vm.form, "country_of_registration_id", $$v)},expression:"form.country_of_registration_id"}}),_vm._l((errors),function(error,index){return _c('b-form-invalid-feedback',{key:index,attrs:{"state":errors.length === 0}},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"ЄДРПОУ","rules":{
          required: true,
          codeRegex: _vm.codeRegexRule,
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label":"ЄДРПОУ"}},[_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_vm._l((errors),function(error,index){return _c('b-form-invalid-feedback',{key:index,attrs:{"state":errors.length === 0}},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Належність до терористів"}},[_c('b-form-radio-group',{attrs:{"stacked":"","options":[
            { text: 'Так', value: true },
            { text: 'Нi', value: false },
          ],"plain":true},model:{value:(_vm.form.is_terrorist),callback:function ($$v) {_vm.$set(_vm.form, "is_terrorist", $$v)},expression:"form.is_terrorist"}})],1),_c('b-form-group',{attrs:{"label":"Належність до санкцій"}},[_c('b-form-radio-group',{attrs:{"stacked":"","options":[
            { text: 'Так', value: true },
            { text: 'Нi', value: false },
          ],"plain":true},model:{value:(_vm.form.has_sanctions),callback:function ($$v) {_vm.$set(_vm.form, "has_sanctions", $$v)},expression:"form.has_sanctions"}})],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[(_vm.$auth.can(_vm.$stringConstants('PERMISSION_CREATE_COUNTERPART')))?_c('b-button',{attrs:{"type":"submit","size":"sm","variant":_vm.index ? 'primary' : 'success'}},[_c('i',{staticClass:"fa fa-dot-circle-o"}),_vm._v(" "+_vm._s(_vm.index ? "Зберегти" : "Додати")+" ")]):_vm._e()],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }