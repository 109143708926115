<template>
  <b-list-group>
    <b-list-group-item
      v-for="(item, index) in jobs"
      :key="index"
      class="flex-column align-items-start"
    >
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">
          {{ item.name.name }}
        </h5>
      </div>
      <div class="mb-1">
        {{ prepareText(item) }}
      </div>
      <div class="mb-1" v-for="protocol in item.protocols" :key="protocol.id">
        {{ protocol.created_at | dateTime }}
        {{ prepareTextProtocol(item, protocol) }}
        <b-button
          v-if="hasPayloadData(item, protocol)"
          @click.stop="documentDetails(item, protocol, 'modalInfo')"
        >
          Переглянути
        </b-button>
      </div>
    </b-list-group-item>
    <b-modal id="modalInfo" size="lg" ok-only>
      <div v-html="modalInfo">{{ modalInfo }}</div>
    </b-modal>
  </b-list-group>
</template>

<script>
import {
  PENDING_STATE,
  PROGRESS_STATE,
  DONE_STATE,
  NEED_TO_ACTION_STATE,
  APPROVED_STATE,
  CANCELED_STATE,
  ERROR_STATE,
  PASSPORT_JOB_VALUE,
  PASSPORT_CARD_ID_JOB_VALUE,
  LEGAL_SANCTION_JOB_VALUE,
  INDIVIDUAL_SANCTION_JOB_VALUE,
  TERRORIST_JOB_VALUE,
  PUBLIC_PERSON_JOB_VALUE,
  COUNTRY_AGGRESSOR_JOB_VALUE,
  FILLING_COUNTERPART_JOB_VALUE,
  TASK_PUBLIC_PERSON_JOB_VALUE,
  TASK_SANCTION_JOB_VALUE
} from "@/constants";
import modal from "bootstrap/js/src/modal";
export default {
  computed: {
    modal() {
      return modal;
    }
  },
  props: ["jobs"],
  name: "Protocol",
  data() {
    return {
      modalInfo: null,
      countries: {
        es: "Іспанії",
        gb: "Британії",
        us: "США",
        ca: "Канаді",
        ch: "Чехії",
        au: "Австрії",
        jp: "Японії",
        pl: "Польші",
        ua: "Україні",
        nz: "Новій Зеландії"
      },
      names: {
        ua: "Українскою",
        ru: "Російською",
        en: "Англійською"
      }
    };
  },
  methods: {
    makePassportText(data, protocol) {
      this.modalInfo =
        "<b>Документ, що посвідчує особу найменування: </b>" +
          protocol.payload.type.name +
          "<br>" +
          "<b>" +
          (data.name.value === PASSPORT_JOB_VALUE
            ? "Серія та номер:"
            : "Номер") +
          "</b>" +
          data.name.value ===
        PASSPORT_JOB_VALUE
          ? protocol.payload.series + " "
          : "" +
            protocol.payload.number +
            "<br>" +
            "<b>Місце де був додан документ: </b>" +
            protocol.payload.ovd +
            "<br>" +
            "<b>Статус: </b>" +
            protocol.payload.status.name +
            "<br>" +
            "<b>Дата заявки: </b>" +
            this.$options.filters.date(protocol.payload.theft_date) +
            "<br>" +
            "<b>Дата внесення інформації: </b>" +
            this.$options.filters.date(protocol.payload.insert_date);
    },
    makeSanctionText(data, protocol) {
      const sanction =
        data.name.value === LEGAL_SANCTION_JOB_VALUE ? "legal" : "individual";

      Object.entries(protocol.payload[sanction + "_sanction"]).forEach(
        ([key, value]) => {
          if (key === "number") {
            this.modalInfo = "<b>Номер: </b>" + value + "<br>";
          }
          if (key.includes("sanctions_")) {
            const lang = key.split("_")[1];
            const url = protocol.payload[sanction + "_sanction"]["url_" + lang];

            if (value || url) {
              this.modalInfo +=
                "<b>Санкції в " + this.countries[lang] + ": </b>";
              this.modalInfo += value !== null ? value + " " : "";
              this.modalInfo +=
                url !== null && url !== ""
                  ? "<a target='_blank' href=" + url + ">Посилання</a>"
                  : "";
              this.modalInfo += "<br>";
            }
          }
          if (key === "reason" && value !== null && value !== "") {
            this.modalInfo += "<b>Причина: </b>" + value + "<br>";
          }
          if (key.includes("name_")) {
            const lang = key.split("_")[1];
            this.modalInfo +=
              "<b>" +
              (sanction === "individual" ? "ПІБ " : "Компанія ") +
              this.names[lang] +
              ": </b>";
            this.modalInfo += value !== null ? value : "";
            this.modalInfo += "<br>";
          }
        }
      );
    },
    makeTerroristText(data, protocol) {
      this.modalInfo =
        "<b>Номер: </b>" + protocol.payload.terrorist.number + "<br>";
      this.modalInfo +=
        "<b>Дата: </b>" +
        this.$options.filters.date(protocol.payload.terrorist.entry_date) +
        "<br>";
      this.modalInfo +=
        "<b>Причина: </b>" + protocol.payload.terrorist.reason + "<br>";
      this.modalInfo +=
        "<b>ПІБ або назва: </b>" +
        protocol.payload.terrorist.names.join("<br>") +
        "<br>";
    },
    makePersonText(data, protocol) {
      this.modalInfo = "";
      if (protocol.payload.person !== null) {
        this.modalInfo =
          "<b>ПІБ публічної особи: </b>" +
          protocol.payload.person.name +
          "<br>";
        this.modalInfo +=
          "<b>Опис: </b>" + protocol.payload.person.description + "<br>";
        this.modalInfo +=
          "<b>Дата народження: </b>" +
          (protocol.payload.person.birth ? protocol.payload.person.birth : "") +
          "<br>";
      }
      if (protocol.payload.related_person !== null) {
        this.modalInfo +=
          "<b>ПІБ звʼязаної особи з публічною: </b>" +
          protocol.payload.related_person.name +
          "<br>";

        this.modalInfo +=
          "<b>Опис: </b>" +
          protocol.payload.related_person.description +
          "<br>";
        this.modalInfo +=
          "<b>Дата народження: </b>" +
          (protocol.payload.related_person.birth
            ? protocol.payload.related_person.birth
            : "") +
          "<br>";
      }
    },
    documentDetails(data, protocol, modalId) {
      if (
        data.name.value === PASSPORT_JOB_VALUE ||
        data.name.value === PASSPORT_CARD_ID_JOB_VALUE
      ) {
        this.makePassportText(data, protocol);
      } else if (
        data.name.value === LEGAL_SANCTION_JOB_VALUE ||
        data.name.value === INDIVIDUAL_SANCTION_JOB_VALUE
      ) {
        this.makeSanctionText(data, protocol);
      } else if (data.name.value === TERRORIST_JOB_VALUE) {
        this.makeTerroristText(data, protocol);
      } else if (data.name.value === PUBLIC_PERSON_JOB_VALUE) {
        this.makePersonText(data, protocol);
      } else {
        this.modalInfo = data;
      }

      this.$root.$emit("bv::show::modal", modalId);
    },
    hasPayloadData(item, protocol) {
      return (
        (protocol.to === DONE_STATE &&
          protocol.payload !== null &&
          (item.name.value === PASSPORT_JOB_VALUE ||
            item.name.value === PASSPORT_CARD_ID_JOB_VALUE)) ||
        (protocol.to === DONE_STATE &&
          protocol.payload !== null &&
          protocol.payload.terrorist !== null &&
          item.name.value === TERRORIST_JOB_VALUE) ||
        (protocol.to === DONE_STATE &&
          protocol.payload !== null &&
          ((protocol.payload.individual_sanction !== null &&
            item.name.value === INDIVIDUAL_SANCTION_JOB_VALUE) ||
            (protocol.payload.legal_sanction !== null &&
              item.name.value === LEGAL_SANCTION_JOB_VALUE))) ||
        (protocol.to === DONE_STATE &&
          protocol.payload !== null &&
          (protocol.payload.person !== null ||
            protocol.payload.related_person !== null) &&
          item.name.value === PUBLIC_PERSON_JOB_VALUE) ||
        (protocol.to === DONE_STATE &&
          protocol.payload !== null &&
          protocol.payload.clarification &&
          protocol.payload.clarification.is_aggressor === true &&
          item.name.value === COUNTRY_AGGRESSOR_JOB_VALUE)
      );
    },
    prepareText(item) {
      if (item.name.value === PASSPORT_JOB_VALUE) {
        return `Серія та номер: ${item.protocols[0].payload.series} ${item.protocols[0].payload.number}`;
      } else if (item.name.value === PASSPORT_CARD_ID_JOB_VALUE) {
        return `Номер: ${item.protocols[0].payload.number}`;
      } else if (
        item.name.value === PUBLIC_PERSON_JOB_VALUE ||
        item.name.value === INDIVIDUAL_SANCTION_JOB_VALUE ||
        item.name.value === TERRORIST_JOB_VALUE
      ) {
        return `ПІБ: ${item.protocols[0].payload.name}`;
      } else if (item.name.value === LEGAL_SANCTION_JOB_VALUE) {
        return `Компанія: ${item.protocols[0].payload.name}`;
      } else if (
        item.name.value === COUNTRY_AGGRESSOR_JOB_VALUE ||
        item.name.value === FILLING_COUNTERPART_JOB_VALUE
      ) {
        return `Код: ${item.protocols[0].payload.code}`;
      }
    },
    prepareTextProtocol(item, protocol) {
      if (protocol.to === PENDING_STATE) {
        return "очікує початку виконання перевірки";
      } else if (protocol.to === PROGRESS_STATE) {
        return "триває перевірка";
      } else if (protocol.to === ERROR_STATE) {
        return `виникла помилка під час перевірки: ${protocol.payload.exception}`;
      } else if (protocol.to === NEED_TO_ACTION_STATE) {
        if (
          item.name.value === PASSPORT_JOB_VALUE &&
          protocol.payload !== null
        ) {
          return "завершено перевірку, знайдено паспорт";
        } else if (
          item.name.value === PASSPORT_CARD_ID_JOB_VALUE &&
          protocol.payload !== null
        ) {
          return "завершено перевірку, знайдено паспорт (ID картка)";
        } else if (
          item.name.value === TERRORIST_JOB_VALUE &&
          protocol.payload.terrorist !== null
        ) {
          return "завершено перевірку, знайдено терориста";
        } else if (
          item.name.value === INDIVIDUAL_SANCTION_JOB_VALUE &&
          protocol.payload.individual_sanction !== null
        ) {
          return "завершено перевірку, встановлено фізичну санкцію";
        } else if (
          item.name.value === LEGAL_SANCTION_JOB_VALUE &&
          protocol.payload.legal_sanction !== null
        ) {
          return "завершено перевірку, знайдено юридичну санкцію";
        } else if (
          item.name.value === COUNTRY_AGGRESSOR_JOB_VALUE &&
          protocol.payload.clarification.is_aggressor
        ) {
          return "завершено перевірку, знайдено країну агресора агресора";
        } else if (item.name.value === FILLING_COUNTERPART_JOB_VALUE) {
          return "завершено перевірку, знайдено данні Єдиного державного реєстру";
        } else if (
          item.name.value === PUBLIC_PERSON_JOB_VALUE &&
          (protocol.payload.person !== null ||
            protocol.payload.related_person !== null)
        ) {
          return "завершено перевірку, знайдено публічну особу або звʼязки з нею";
        } else if (
          [TASK_PUBLIC_PERSON_JOB_VALUE, TASK_SANCTION_JOB_VALUE].includes(
            item.name.value
          )
        ) {
          return "завершено перевірку, є зміни";
        } else {
          return `завершено перевірку, жодних співпадінь не знайдено`;
        }
      } else if (protocol.to === APPROVED_STATE) {
        return `користвуч ${protocol.causer.email} підтвердив співпадіння, співпадіння внесено до картки контрагента.`;
      } else if (protocol.to === CANCELED_STATE) {
        return `користвуч ${protocol.causer.email} відхилив співпадіння, на протязі одного року дане співпадіння буде ігноруватись.`;
      } else if (protocol.to === DONE_STATE)
        if (
          item.name.value === PASSPORT_JOB_VALUE &&
          protocol.payload !== null
        ) {
          return "завершено перевірку, встановлено паспорт";
        } else if (
          item.name.value === PASSPORT_CARD_ID_JOB_VALUE &&
          protocol.payload !== null
        ) {
          return "завершено перевірку, встановлено паспорт (ID картка)";
        } else if (
          item.name.value === TERRORIST_JOB_VALUE &&
          protocol.payload.terrorist !== null
        ) {
          return "завершено перевірку, встановлено терориста";
        } else if (
          item.name.value === INDIVIDUAL_SANCTION_JOB_VALUE &&
          protocol.payload.individual_sanction !== null
        ) {
          return "завершено перевірку, встановлено фізичну санкцію";
        } else if (
          item.name.value === LEGAL_SANCTION_JOB_VALUE &&
          protocol.payload.legal_sanction !== null
        ) {
          return "завершено перевірку, встановлено юридичну санкцію";
        } else if (
          item.name.value === COUNTRY_AGGRESSOR_JOB_VALUE &&
          protocol.payload.clarification.is_aggressor
        ) {
          return "завершено перевірку, встановлено країну агресора агресора";
        } else if (item.name.value === FILLING_COUNTERPART_JOB_VALUE) {
          if (protocol.payload.exception) {
            return "Завершено перевірку. " + protocol.payload.exception;
          }
          return "завершено перевірку, наповнено з Єдиного державного реєстру";
        } else if (
          item.name.value === PUBLIC_PERSON_JOB_VALUE &&
          (protocol.payload.person !== null ||
            protocol.payload.related_person !== null)
        ) {
          return "завершено перевірку, встановлено публічну особу або звʼязки з нею";
        } else if (
          [TASK_PUBLIC_PERSON_JOB_VALUE, TASK_SANCTION_JOB_VALUE].includes(
            item.name.value
          )
        ) {
          if (protocol.payload === null) {
            return "змін немає";
          } else {
            return "зміни застосовані";
          }
        } else {
          return `завершено перевірку, жодних співпадінь не знайдено`;
        }
    }
  }
};
</script>
