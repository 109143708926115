<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group label-size="lg">
        <!-- Серія та номер ліцензії/дозволу -->
        <ValidationProvider
          name="серія та номер ліцензії/дозволу"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-group label="Серія та номер ліцензії/дозволу">
            <b-form-input
              type="text"
              v-model="form.number"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Серія та номер ліцензії/дозволу -->

        <!-- Ким виданий -->
        <ValidationProvider
          name="ким виданий"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-group label="Ким виданий">
            <b-form-input
              type="text"
              v-model="form.issued_by"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Ким виданий -->

        <!-- Термін дії -->
        <ValidationProvider name="термін дії" v-slot="{ errors }">
          <b-form-group label="Термін дії">
            <b-form-input
              v-model="form.validity_date"
              type="date"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Термін дії -->
      </b-form-group>

      <div slot="footer">
        <b-button
          v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
          type="submit"
          size="sm"
          :variant="index ? 'primary' : 'success'"
        >
          <i class="fa fa-dot-circle-o"></i> {{ index ? "Зберегти" : "Додати" }}
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
export default {
  name: "LicenseForm",
  props: {
    index: null
  },
  computed: {
    form: function() {
      if (this.index === null)
        return {
          counterpart_id: this.$route.params.id,
          number: "",
          issued_by: "",
          validity_date: ""
        };
      let form = this.$store.getters["counterpart/getLicenses"][this.index];
      return {
        id: form.id,
        number: form.number,
        issued_by: form.issued_by,
        validity_date: form.validity_date
      };
    }
  },
  methods: {
    onSubmit() {
      if (this.index === null)
        this.$store.dispatch("counterpart/createLicense", this.form);
      else this.$store.dispatch("counterpart/updateLicense", this.form);
      this.$root.$emit("bv::hide::modal", "modalLicenses");
    }
  }
};
</script>
