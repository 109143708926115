<template>
  <b-list-group v-if="$auth.can(getPermissionName())">
    <b-list-group-item
      v-for="(item, index) in history"
      :key="index"
      class="flex-column align-items-start"
    >
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">
          {{ item.title }}
          <b-badge :variant="prepareColorEvent(item.event)">
            {{ prepareTextEvent(item.event) }}
          </b-badge>
        </h5>
        <small class="text-right">
          <i>{{ item.created_at | dateTime }} <br />{{ item.causer }} </i>
        </small>
      </div>
      <div v-for="(change, index) in item.changes" :key="index" class="mb-1">
        <b>{{ change.title }}</b>
        <p>
          Значення: {{ prepareValueInChanges(change.value) }} <br />
          <template v-if="change.old_value !== null">
            Старе значення: {{ prepareValueInChanges(change.old_value) }}
          </template>
        </p>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { mapGetters } from "vuex";
import { PERMISSION_VIEW_COUNTERPART_HISTORY } from "@/constants";

export default {
  name: "History",
  computed: {
    ...mapGetters({
      history: "counterpart/getHistory"
    })
  },
  methods: {
    getPermissionName() {
      return PERMISSION_VIEW_COUNTERPART_HISTORY;
    },
    prepareColorEvent(value) {
      if (value === "created") return "primary";
      else if (value === "updated") return "success";
      else if (value === "restored") return "secondary";
      else if (value === "deleted") return "danger";
    },
    prepareTextEvent(value) {
      if (value === "created") return "Створено";
      else if (value === "updated") return "Оновлено";
      else if (value === "restored") return "Відновлено";
      else if (value === "deleted") return "Видалено";
    },
    prepareValueInChanges(value) {
      if (typeof value === "boolean") {
        return value ? "Так" : " Ні";
      }

      if (value === null) {
        return "-";
      }

      return value;
    }
  }
};
</script>
