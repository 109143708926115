<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="formValidation">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label="Власник"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <b-form-checkbox
          v-model="checked"
          name="check-button"
          switch
          :disabled="!!propOwner"
        >
          <template v-if="checked">Юридична особа</template>
          <template v-else>Фізична особа</template>
        </b-form-checkbox>
        <ValidationProvider
          v-if="checked"
          name="Назва"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_NAME'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors }"
          vid="name"
        >
          <b-form-group label="Назва">
            <b-form-input
              type="text"
              v-model="form.name"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-if="!checked"
          name="Прізвище"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors, dirty }"
          vid="last_name"
        >
          <b-form-group label="Прізвище">
            <b-form-input
              type="text"
              v-model="form.last_name"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-if="!checked"
          name="ім'я"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors, dirty }"
          vid="first_name"
        >
          <b-form-group label="Ім'я">
            <b-form-input
              type="text"
              v-model="form.first_name"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-if="!checked"
          name="по батькові"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
          }"
          v-slot="{ errors, dirty }"
          vid="middle_name"
        >
          <b-form-group label="По батькові">
            <b-form-input
              type="text"
              v-model="form.middle_name"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <!-- Країна реєстрації -->
        <ValidationProvider
          v-slot="{ errors }"
          name="країна реєстрації"
          rules="required"
          vid="country_of_registration_id"
        >
          <b-form-group label="Країна реєстрації">
            <b-form-select
              v-model="form.country_of_registration_id"
              :state="errors.length > 0 ? false : null"
              :options="countryList"
              text-field="name.short-name"
              value-field="id"
            >
            </b-form-select>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Країна реєстрації -->

        <ValidationProvider
          :name="checked ? 'ЄДРПОУ' : 'РНОКПП'"
          :rules="{
            required: true,
            codeRegex: codeRegexRule,
          }"
          v-slot="{ errors }"
          vid="code"
        >
          <b-form-group :label="checked ? 'ЄДРПОУ' : 'РНОКПП'">
            <b-form-input
              type="text"
              v-model="form.code"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="адреса реєстрації"
          rules="max:255"
          v-slot="{ errors }"
          vid="address"
        >
          <b-form-group label="Адреса реєстрації">
            <b-form-input
              type="text"
              v-model="form.address"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="частка у капіталі"
          :rules="{ regex: /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,12})?)$/ }"
          v-slot="{ errors }"
          vid="owner_part"
        >
          <b-form-group label="Частка у капіталі">
            <b-form-input
              type="text"
              placeholder="0.00"
              v-model="form.owner_part"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Дата початку"
          v-slot="{ errors, dirty }"
          vid="started_at"
        >
          <b-form-group label="Дата початку">
            <b-form-input
              type="date"
              v-model="form.started_at"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Дата закінчення"
          v-slot="{ errors, dirty }"
          vid="ended_at"
        >
          <b-form-group label="Дата закінчення">
            <b-form-input
              type="date"
              v-model="form.ended_at"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами"
          v-slot="{ errors, dirty }"
          vid="is_public"
        >
          <b-form-group
            label="Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами"
          >
            <b-form-radio-group
              stacked
              v-model="form.is_public"
              :options="[
                { text: 'Так', value: true },
                { text: 'Нi', value: false },
              ]"
              :plain="true"
            >
            </b-form-radio-group>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Належність до терористів"
          v-slot="{ errors, dirty }"
          vid="is_terrorist"
        >
          <b-form-group label="Належність до терористів">
            <b-form-radio-group
              stacked
              v-model="form.is_terrorist"
              :options="[
                { text: 'Так', value: true },
                { text: 'Нi', value: false },
              ]"
              :plain="true"
            >
            </b-form-radio-group>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Належність до санкцій"
          v-slot="{ errors, dirty }"
          vid="has_sanctions"
        >
          <b-form-group label="Належність до санкцій">
            <b-form-radio-group
              stacked
              v-model="form.has_sanctions"
              :options="[
                { text: 'Так', value: true },
                { text: 'Нi', value: false },
              ]"
              :plain="true"
            >
            </b-form-radio-group>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <div slot="footer">
        <b-button
          v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
          type="submit"
          size="sm"
          :variant="propOwner ? 'primary' : 'success'"
        >
          <i class="fa fa-dot-circle-o"></i>
          {{ propOwner ? "Зберегти" : "Додати" }}
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
export default {
  name: "OwnerForm",
  props: {
    propOwner: {
      type: Object | null,
      required: true,
    },
  },
  components: { ValidationProvider },
  data() {
    return {
      checked: false,
      form: {
        id: null,
        name: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        code: null,
        address: null,
        owner_part: null,
        type: null,
        is_public: false,
        is_terrorist: false,
        has_sanctions: false,
        started_at: null,
        ended_at: null,
        country_of_registration_id: null,
      },
    };
  },
  created() {
    for (const [key] of Object.entries(this.form)) {
      if (this.propOwner) {
        this.form[key] = this.propOwner[key];
        this.form["country_of_registration_id"] =
          this.propOwner.country_of_registration.id;
      } else {
        this.form[key] =
          key === "is_public" ||
          key === "is_terrorist" ||
          key === "has_sanctions"
            ? false
            : null;
      }
    }

    if (this.propOwner) {
      this.checked = this.propOwner.type.value === "legal-person";
    }
  },
  computed: {
    ...mapGetters({
      countryList: "dictionary/allCountries",
    }),
    codeRegexRule() {
      if (
        this.countryList.find(
          (c) => c.id === this.form?.country_of_registration_id
        )?.value === this.$stringConstants("CODE_UKRAINE")
      ) {
        if (this.checked) {
          return "(^[\\d]{8}$)";
        }
        return "(^[\\d]{10}$)";
      }
      return "^[\\w-]{1,21}$";
    },
  },
  methods: {
    getChangedOwnerFields() {
      return Object.keys(this.$refs.formValidation.fields).reduce(
        (accumulator, key) => {
          if (
            this.$refs.formValidation.fields[key].changed === true &&
            key in this.form
          ) {
            accumulator.push(key);
          }

          return accumulator;
        },
        []
      );
    },
    onSubmit() {
      let changedOwnerFields = this.getChangedOwnerFields();

      let fields = Object.keys(this.form).reduce((accumulator, key) => {
        if (changedOwnerFields.includes(key) || key === "id") {
          accumulator[key] = this.form[key];
        }

        return accumulator;
      }, {});

      fields.counterpart_id = this.$route.params.id;
      fields.type = this.checked ? "legal-person" : "individual";

      this.$store
        .dispatch(
          this.propOwner
            ? "counterpart/updateOwner"
            : "counterpart/createOwner",
          fields
        )
        .then(() => {
          this.$root.$emit("bv::hide::modal", "modalOwner");
        });
    },
  },
};
</script>
