<template>
  <b-modal
    v-if="iseiData"
    id="isei-verification"
    hide-footer
    hide-header
    size="lg"
  >
    <table class="table table-striped">
      <tbody>
        <tr>
          <th>Тип аутентифікації, що було обрано користувачем:</th>
          <td>{{ iseiData.auth_type }}</td>
        </tr>
        <tr>
          <th>Реквізити видавника сертифіката (Надавач):</th>
          <td>{{ iseiData.issuer }}</td>
        </tr>
        <tr>
          <th>Загальне ім’я Надавача:</th>
          <td>{{ iseiData.issuercn }}</td>
        </tr>
        <tr>
          <th>Реєстраційний номер сертифіката у Надавача:</th>
          <td>{{ iseiData.serial }}</td>
        </tr>
        <tr>
          <th>Реквізити власника сертифіката (користувача):</th>
          <td>{{ iseiData.subject }}</td>
        </tr>
        <tr>
          <th>Загальне ім’я користувача:</th>
          <td>{{ iseiData.subjectcn }}</td>
        </tr>
        <tr>
          <th>Місто (населений пункт) користувача:</th>
          <td>{{ iseiData.locality }}</td>
        </tr>
        <tr>
          <th>Область (регіон) користувача:</th>
          <td>{{ iseiData.state }}</td>
        </tr>
        <tr>
          <th>Найменування організації користувача:</th>
          <td>{{ iseiData.o }}</td>
        </tr>
        <tr>
          <th>Назва підрозділу організації користувача:</th>
          <td>{{ iseiData.ou }}</td>
        </tr>
        <tr>
          <th>Посада користувача:</th>
          <td>{{ iseiData.title }}</td>
        </tr>
        <tr>
          <th>Ім’я користувача:</th>
          <td>{{ iseiData.givenname }}</td>
        </tr>
        <tr>
          <th>По батькові користувача:</th>
          <td>{{ iseiData.middlename }}</td>
        </tr>
        <tr>
          <th>Прізвище користувача:</th>
          <td>{{ iseiData.lastname }}</td>
        </tr>
        <tr>
          <th>Адреса ел. пошти (e-mail) користувача:</th>
          <td>{{ iseiData.email }}</td>
        </tr>
        <tr>
          <th>Документи:</th>
          <td colspan="2">{{ iseiData.documents }}</td>
        </tr>
        <tr>
          <th>Дата народження:</th>
          <td>{{ iseiData.birthday }}</td>
        </tr>
        <tr>
          <th>Паспорт:</th>
          <td colspan="2">{{ iseiData.passport }}</td>
        </tr>
        <tr>
          <th>Юридична адреса:</th>
          <td colspan="2">{{ iseiData.addressJuridical }}</td>
        </tr>
        <tr>
          <th>Адреса (фізична) користувача:</th>
          <td>{{ iseiData.address }}</td>
        </tr>
        <tr>
          <th>Телефон користувача:</th>
          <td>{{ iseiData.phone }}</td>
        </tr>
        <tr>
          <th>DNS-ім'я користувача:</th>
          <td>{{ iseiData.dns }}</td>
        </tr>
        <tr>
          <th>Код за ЄДРПОУ користувача:</th>
          <td>{{ iseiData.edrpoucode }}</td>
        </tr>
        <tr>
          <th>РНОКПП користувача або серія та номер паспорта:</th>
          <td>{{ iseiData.drfocode }}</td>
        </tr>
        <tr>
          <th>Персональні ідентифікатори</th>
          <td colspan="2">{{ iseiData.personIdentifier }}</td>
        </tr>
        <tr>
          <th>Унікальний номер запису в Єдиному демографічному реєстрі:</th>
          <td>{{ iseiData.unzr }}</td>
        </tr>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "IseiDataModal",
  mixins: [mixins],
  props: {
    iseiData: {
      type: Object,
      required: true,
    },
  },
};
</script>
