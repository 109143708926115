<template>
  <ValidationObserver
    v-slot="{ handleSubmit, invalid, dirty }"
    ref="formValidation"
  >
    <b-form @submit.prevent="onSubmit">
      <div
        v-if="
          isLegal(counterpart ? counterpart.type : null) ||
          isNonResidentLegal(counterpart.type)
        "
      >
        <!-- Дані про виконавчий орган -->
        <ValidationProvider
          vid="executive_depth"
          name="дані про виконавчий орган"
          v-if="isLegal(counterpart.type)"
          :rules="{ regex: /^[-А-Яа-яёЁЇїІіЄєҐґэЭ–,\s]{3,255}$/ }"
          v-slot="{ errors }"
        >
          <b-form-group
            label-class="font-weight-bold"
            label="Дані про виконавчий орган"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              :required="true"
              v-model="form.executive_depth"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          vid="executive_depth"
          name="дані про виконавчий орган"
          v-if="isNonResidentLegal(counterpart.type)"
          :rules="{ regex: /^[-А-Яа-яёЁЇїІіЄєҐґэЭ–,a-zA-Z\s]{3,255}$/ }"
          v-slot="{ errors }"
        >
          <b-form-group
            label-class="font-weight-bold"
            label="Дані про виконавчий орган"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.executive_depth"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Дані про виконавчий орган -->

        <b-form-group
          label="Ідентифікаційні дані осіб, які мають право розпоряджатися рахунками та/або майном"
          label-size="lg"
          label-class="font-weight-bold"
        >
          <b-table
            id="property-commanders-table"
            :fields="propertyCommandersFields"
            :hover="true"
            :outlined="true"
            :fixed="true"
            :small="true"
            :items="propertyCommanders"
            :tbody-tr-class="endedTime"
          >
            <template v-slot:cell(actions)="data">
              <b-button
                variant="ghost-info link"
                @click="editPropertyCommander(data.index)"
                size="sm"
              >
                <i class="fa fa-edit" />
              </b-button>

              <b-button
                v-if="data.item.document"
                variant="link text-success"
                size="sm"
                @click.stop="
                  documentDetails(data, 'propertyParticipantDetails')
                "
              >
                <i class="fa fa-info-circle" />
              </b-button>

              <b-button
                v-if="
                  $auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))
                "
                variant="link text-danger"
                size="sm"
                @click="removePropertyCommander(data.index)"
              >
                <i class="fa fa-close" />
              </b-button>
            </template>

            <template v-slot:cell(is_public)="data">
              {{ data.item.is_public ? "Так" : "Ні" }}
            </template>
            <template v-slot:cell(is_terrorist)="data">
              {{ data.item.is_terrorist ? "Так" : "Ні" }}
            </template>
            <template v-slot:cell(has_sanctions)="data">
              {{ data.item.has_sanctions ? "Так" : "Ні" }}
            </template>
            <template #cell(position)="data">
              {{ data.value.name }}
              {{ data.item.ended_at ? "(до " + data.item.ended_at + ")" : "" }}
            </template>
          </b-table>

          <b-button
            v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
            variant="link text-success"
            size="sm"
            @click="createPropertyCommander()"
            class="mb-2"
          >
            <i class="fa fa-plus" />
          </b-button>

          <b-modal
            id="modalPropertyCommander"
            hide-header
            hide-footer
            size="lg"
          >
            <property-commander-form
              :prop-property-commander="propertyCommander"
            />
          </b-modal>

          <b-modal id="propertyParticipantDetails" size="lg" ok-only>
            <pre v-html="docDetails">{{ docDetails }}</pre>
          </b-modal>
        </b-form-group>

        <b-form-group
          label="Дані про структуру власності (пряме володіння) із зазначенням частки"
          label-size="lg"
          label-class="font-weight-bold"
        >
          <b-table
            :fields="ownerFields"
            :hover="true"
            :small="true"
            :outlined="true"
            :fixed="true"
            :items="owners"
            :tbody-tr-class="endedTime"
          >
            <template v-slot:cell(actions)="data">
              <b-button
                variant="ghost-info link"
                size="sm"
                @click="editOwner(data.index)"
              >
                <i class="fa fa-edit" />
              </b-button>

              <b-button
                v-if="
                  $auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))
                "
                variant="link text-danger"
                size="sm"
                @click="removeOwner(data.index)"
              >
                <i class="fa fa-close" />
              </b-button>
            </template>

            <template v-slot:cell(is_public)="data">
              {{ data.item.is_public ? "Так" : "Ні" }}
            </template>
            <template v-slot:cell(is_terrorist)="data">
              {{ data.item.is_terrorist ? "Так" : "Ні" }}
            </template>
            <template v-slot:cell(has_sanctions)="data">
              {{ data.item.has_sanctions ? "Так" : "Ні" }}
            </template>
            <template #cell(owner_part)="data">
              {{ data.value }}
              {{ data.item.ended_at ? "(до " + data.item.ended_at + ")" : "" }}
            </template>
          </b-table>

          <b-button
            v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
            variant="link text-success"
            size="sm"
            @click="createOwner()"
            class="mb-2"
          >
            <i class="fa fa-plus" />
          </b-button>

          <b-modal id="modalOwner" hide-header hide-footer size="lg">
            <owner-form :prop-owner="owner" />
          </b-modal>
        </b-form-group>

        <b-form-group
          label="Дані про фізичних осіб, що є кінцевими бенефіціарними власниками (контролерами)"
          label-size="lg"
          label-class="font-weight-bold"
        >
          <b-table
            :fields="beneficialOwnerFields"
            :hover="true"
            :small="true"
            :outlined="true"
            :fixed="true"
            :items="beneficialOwners"
            :tbody-tr-class="endedTime"
          >
            <template v-slot:cell(actions)="data">
              <b-button
                variant="ghost-info link"
                size="sm"
                @click="editBeneficialOwner(data.index)"
              >
                <i class="fa fa-edit" />
              </b-button>

              <b-button
                v-if="
                  $auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))
                "
                variant="link text-danger"
                size="sm"
                @click="removeBeneficialOwner(data.index)"
              >
                <i class="fa fa-close" />
              </b-button>
            </template>

            <template v-slot:cell(is_public)="data">
              {{ data.item.is_public ? "Так" : "Ні" }}
            </template>
            <template v-slot:cell(is_terrorist)="data">
              {{ data.item.is_terrorist ? "Так" : "Ні" }}
            </template>
            <template v-slot:cell(has_sanctions)="data">
              {{ data.item.has_sanctions ? "Так" : "Ні" }}
            </template>
            <template #cell(influence_basis)="data">
              {{ data.value }}
              {{ data.item.ended_at ? "(до " + data.item.ended_at + ")" : "" }}
            </template>
          </b-table>

          <b-button
            v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
            variant="link text-success"
            size="sm"
            @click="createBeneficialOwner()"
            class="mb-2"
          >
            <i class="fa fa-plus" />
          </b-button>

          <b-modal id="modalBeneficialOwner" hide-header hide-footer size="lg">
            <beneficial-owner-form :prop-beneficial-owner="beneficialOwner" />
          </b-modal>
        </b-form-group>

        <!-- Наявність відокремлених підрозділів -->
        <ValidationProvider
          vid="has_separate_units"
          name="наявність відокремлених підрозділів"
        >
          <b-form-group
            label="Наявність відокремлених підрозділів"
            label-size="lg"
            label-class="font-weight-bold"
          >
            <b-form-group label-cols="3" :horizontal="true">
              <b-form-radio-group
                v-model="form.has_separate_units"
                :options="[
                  { text: 'Так', value: true },
                  { text: 'Нi', value: false },
                ]"
                @change="removeAllSeparateUnits()"
              />
            </b-form-group>
            <!-- END: Наявність відокремлених підрозділів -->

            <b-form-group v-if="form.has_separate_units">
              <b-table
                :fields="separateUnitFields"
                :hover="true"
                :small="true"
                :outlined="true"
                :fixed="true"
                :items="separateUnits"
              >
                <template v-slot:cell(actions)="data">
                  <b-button
                    variant="ghost-info link"
                    size="sm"
                    @click="editSeparateUnit(data.index)"
                  >
                    <i class="fa fa-edit" />
                  </b-button>

                  <b-button
                    variant="link text-danger"
                    size="sm"
                    v-if="
                      $auth.can(
                        $stringConstants('PERMISSION_CREATE_COUNTERPART')
                      )
                    "
                    @click="removeSeparateUnit(data.index)"
                  >
                    <i class="fa fa-close" />
                  </b-button>
                </template>
                <template v-slot:cell(is_terrorist)="data">
                  {{ data.item.is_terrorist ? "Так" : "Ні" }}
                </template>
                <template v-slot:cell(has_sanctions)="data">
                  {{ data.item.has_sanctions ? "Так" : "Ні" }}
                </template>
              </b-table>

              <b-button
                v-if="
                  $auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))
                "
                variant="link text-success"
                size="sm"
                @click="createSeparateUnit()"
                class="mb-2"
              >
                <i class="fa fa-plus" />
              </b-button>

              <b-modal id="modalSeparateUnit" hide-header hide-footer size="lg">
                <separate-unit-form :index="separateUnitIndexForEditing" />
              </b-modal>
            </b-form-group>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          vid="has_parent_company"
          name="наявність материнської компанії"
        >
          <b-form-group
            label="Наявність материнської компанії"
            label-size="lg"
            label-class="font-weight-bold"
          >
            <!-- Наявність материнської компанії -->
            <b-form-group label-cols="3" :horizontal="true">
              <b-form-radio-group
                v-model="form.has_parent_company"
                :options="[
                  { text: 'Так', value: true },
                  { text: 'Нi', value: false },
                ]"
                @change="removeAllParentCompanies()"
              />
            </b-form-group>
            <!-- END: Наявність материнської компанії -->

            <b-form-group v-if="form.has_parent_company">
              <b-table
                :fields="parentCompanyFields"
                :hover="true"
                :small="true"
                :outlined="true"
                :fixed="true"
                :items="parentCompanies"
              >
                <template v-slot:cell(actions)="data">
                  <b-button
                    variant="ghost-info link"
                    size="sm"
                    @click="editParentCompany(data.index)"
                  >
                    <i class="fa fa-edit" />
                  </b-button>
                  <b-button
                    v-if="
                      $auth.can(
                        $stringConstants('PERMISSION_CREATE_COUNTERPART')
                      )
                    "
                    variant="link text-danger"
                    size="sm"
                    @click="removeParentCompany(data.index)"
                  >
                    <i class="fa fa-close" />
                  </b-button>
                </template>
                <template v-slot:cell(is_terrorist)="data">
                  {{ data.item.is_terrorist ? "Так" : "Ні" }}
                </template>
                <template v-slot:cell(has_sanctions)="data">
                  {{ data.item.has_sanctions ? "Так" : "Ні" }}
                </template>
              </b-table>

              <b-button
                v-if="
                  $auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))
                "
                variant="link text-success"
                size="sm"
                @click="createParentCompany()"
                class="mb-2"
              >
                <i class="fa fa-plus" />
              </b-button>

              <b-modal
                id="modalParentCompany"
                hide-header
                hide-footer
                size="lg"
              >
                <parent-company-form :index="parentCompanyIndexForEditing" />
              </b-modal>
            </b-form-group>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          vid="is_nonprofit"
          name="клієнт є благодійною/неприбутковою організацією"
        >
          <b-form-group
            label="Клієнт є благодійною/неприбутковою організацією."
            label-size="lg"
            label-class="font-weight-bold"
          >
            <b-form-group label-cols="3" :horizontal="true">
              <b-form-radio-group
                v-model="form.is_nonprofit"
                :options="[
                  { text: 'Так', value: true },
                  { text: 'Нi', value: false },
                ]"
              />
            </b-form-group>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          vid="nonprofit_description"
          name="клієнт є благодійною/неприбутковою організацією коментарі"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Клієнт є благодійною/неприбутковою організацією, коментарі."
            label-size="lg"
            label-class="font-weight-bold"
          >
            <!-- Коментарі -->
            <b-form-group label="Коментарі" label-cols="3" :horizontal="true">
              <b-form-input
                :disabled="!form.is_nonprofit"
                type="text"
                v-model="form.nonprofit_description"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- END: Коментарі -->
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          vid="risk_limitation"
          name="заходи для обмеження ризику використання з метою легалізації (відмивання) доходів, одержаних злочинним шляхом, або фінансування тероризму чи фінансування розповсюдження зброї масового знищення"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Заходи для обмеження ризику використання з метою легалізації (відмивання) доходів, одержаних злочинним шляхом, або фінансування тероризму чи фінансування розповсюдження зброї масового знищення."
            label-size="lg"
            label-class="font-weight-bold"
          >
            <b-form-group label-cols="3" :horizontal="true">
              <b-form-input
                :disabled="!form.is_nonprofit"
                type="text"
                v-model="form.risk_limitation"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          vid="other_risk_limitations"
          name="Інші заходи НПК (заходи для обмеження ризику використання з метою легалізації (відмивання) доходів одержаних злочинним шляхом, або фінансування тероризму чи фінансування розповсюдження зброї масового знищення)"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Інші заходи НПК (заходи для обмеження ризику використання з метою легалізації (відмивання) доходів одержаних злочинним шляхом, або фінансування тероризму чи фінансування розповсюдження зброї масового знищення)."
            label-size="lg"
            label-class="font-weight-bold"
          >
            <b-form-group label-cols="3" :horizontal="true">
              <b-form-input
                type="text"
                v-model="form.other_risk_limitations"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form-group>
        </ValidationProvider>
      </div>

      <b-form-group
        label="Ідентифікаційні дані представника клієнта (за наявності таких осіб)"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <b-table
          id="representatives-table"
          :fields="representativeFields"
          :hover="true"
          :small="true"
          :outlined="true"
          :fixed="true"
          :items="representatives"
          :tbody-tr-class="endedTime"
        >
          <template v-slot:cell(actions)="data">
            <b-button
              variant="ghost-info link"
              size="sm"
              @click="editRepresentative(data.index)"
              ><i class="fa fa-edit"></i
            ></b-button>
            <b-button
              v-if="data.item.document"
              variant="link text-success"
              size="sm"
              @click.stop="
                documentDetails(data, 'propertyRepresentativeDetails')
              "
              ><i class="fa fa-info-circle"></i
            ></b-button>
            <b-button
              v-if="
                $auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))
              "
              variant="link text-danger"
              size="sm"
              @click="removeRepresentative(data.index)"
              ><i class="fa fa-close"></i
            ></b-button>
          </template>

          <template v-slot:cell(is_public)="data">
            {{ data.item.is_public ? "Так" : "Ні" }}
          </template>
          <template v-slot:cell(is_terrorist)="data">
            {{ data.item.is_terrorist ? "Так" : "Ні" }}
          </template>
          <template v-slot:cell(has_sanctions)="data">
            {{ data.item.has_sanctions ? "Так" : "Ні" }}
          </template>
          <template #cell(position)="data">
            {{ data.value.name }}
            {{ data.item.ended_at ? "(до " + data.item.ended_at + ")" : "" }}
          </template>
        </b-table>

        <b-button
          v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
          variant="link text-success"
          size="sm"
          @click="createRepresentative()"
          class="mb-2"
        >
          <i class="fa fa-plus" />
        </b-button>

        <b-modal id="modalRepresentative" hide-header hide-footer size="lg">
          <representative-form :prop-representative="representative" />
        </b-modal>

        <b-modal id="propertyRepresentativeDetails" size="lg" ok-only>
          <pre v-html="docDetails">{{ docDetails }}</pre>
        </b-modal>
      </b-form-group>

      <ValidationProvider
        vid="is_public"
        name="належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами"
      >
        <b-form-group
          label="Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами."
          label-size="lg"
          label-class="font-weight-bold"
        >
          <b-form-group label-cols="3" :horizontal="true">
            <b-form-radio-group
              v-model="form.is_public"
              :options="[
                { text: 'Так', value: true, disabled: true },
                { text: 'Нi', value: false, disabled: true },
              ]"
            />
          </b-form-group>
        </b-form-group>
      </ValidationProvider>

      <!-- Дата виявлення публічності -->
      <ValidationProvider
        vid="publicity_date"
        name="дата виявлення публічності"
        v-if="form.is_public"
        v-slot="{ errors }"
      >
        <b-form-group label="Дата виявлення" label-cols="3" :horizontal="true">
          <b-form-input
            type="date"
            v-model="form.publicity_date"
            :state="errors.length > 0 ? false : null"
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <!-- END: Дата виявлення публічності -->

      <!-- Код типу публічного діяча -->
      <ValidationProvider
        vid="publicity_type_id"
        name="код типу публічного діяча"
        v-if="form.is_public"
        v-slot="{ errors, dirty }"
      >
        <b-form-group
          label="Код типу публічного діяча"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-select
            v-model="form.publicity_type_id"
            :state="errors.length > 0 ? false : null"
          >
            <option
              v-for="option in publicityTypes"
              :key="option.id"
              :value="option.id"
            >
              {{ `(${option.value}) ${option.name}` }}
            </option>
          </b-form-select>
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <!-- END: Код типу публічного діяча -->

      <!-- Дата отримання дозволу керівника установи на встановлення/продовження ділових (договірних) відносин -->
      <ValidationProvider
        vid="manager_approval_date"
        name="дата отримання дозволу керівника установи на встановлення/продовження ділових (договірних) відносин"
        v-if="form.is_public"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Дата отримання дозволу керівника установи на встановлення/продовження ділових (договірних) відносин"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input
            type="date"
            v-model="form.manager_approval_date"
            :state="errors.length > 0 ? false : null"
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <!-- END: Дата отримання дозволу керівника установи на встановлення/продовження ділових (договірних) відносин -->

      <!-- Дата, з якої установа припинила вживати заходи, визначені в частині чотирнадцятій статті 11 Закону про ПВК/ФТ -->
      <ValidationProvider
        vid="measures_termination_date"
        name="дата, з якої установа припинила вживати заходи, визначені в частині чотирнадцятій статті 11 Закону про ПВК/ФТ"
        v-if="form.is_public"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Дата, з якої установа припинила вживати заходи, визначені в частині чотирнадцятій статті 11 Закону про ПВК/ФТ"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input
            type="date"
            v-model="form.measures_termination_date"
            :state="errors.length > 0 ? false : null"
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <!-- END: Дата, з якої установа припинила вживати заходи, визначені в частині чотирнадцятій статті 11 Закону про ПВК/ФТ -->

      <ValidationProvider vid="is_public_description" name="коментарі">
        <b-form-group label="Коментарі" label-cols="3" :horizontal="true">
          <b-form-input
            :disabled="!form.is_public"
            type="text"
            v-model="form.is_public_description"
          />
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        vid="public_measures"
        name="Заходи для з'ясування джерел походження коштів публічних осіб, осіб, близьких або пов'язаних з публічними особами"
        v-if="form.is_public"
        v-slot="{ errors, dirty }"
      >
        <b-form-group
          label="Заходи для з'ясування джерел походження коштів публічних осіб, осіб, близьких або пов'язаних з публічними особами"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input
            :disabled="!form.is_public"
            type="text"
            v-model="form.public_measures"
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        vid="is_terrorist"
        name="клієнт включений до Переліку осіб, пов'язаних із здійсненням терористичної діяльності"
      >
        <b-form-group
          label="Клієнт включений до Переліку осіб, пов'язаних із здійсненням терористичної діяльності."
          label-size="lg"
          label-class="font-weight-bold"
        >
          <b-form-group label-cols="3" :horizontal="true">
            <b-form-radio-group
              v-model="form.is_terrorist"
              :options="[
                { text: 'Так', value: true, disabled: true },
                { text: 'Нi', value: false, disabled: true },
              ]"
            />
          </b-form-group>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider vid="is_terrorist_description" name="коментарі">
        <b-form-group label="Коментарі" label-cols="3" :horizontal="true">
          <b-form-textarea
            rows="3"
            :disabled="!form.is_terrorist"
            type="text"
            v-model="form.is_terrorist_description"
          />
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        vid="has_international_sanctions"
        name="клієнт включений до Переліку осіб стосовно яких застосовано міжнародні санкції"
      >
        <b-form-group
          label="Клієнт включений до Переліку осіб стосовно яких застосовано міжнародні санкції."
          label-size="lg"
          label-class="font-weight-bold"
        >
          <b-form-group label-cols="3" :horizontal="true">
            <b-form-radio-group
              v-model="form.has_international_sanctions"
              :options="[
                { text: 'Так', value: true },
                { text: 'Нi', value: false },
              ]"
            />
          </b-form-group>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        vid="international_sanctions_description"
        name="коментарі"
      >
        <b-form-group label="Коментарі" label-cols="3" :horizontal="true">
          <b-form-textarea
            rows="3"
            :disabled="!form.has_international_sanctions"
            type="text"
            v-model="form.international_sanctions_description"
          />
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        vid="has_sanctions"
        name="клієнт включений до Переліку осіб стосовно яких застосовано санкції РНБО"
      >
        <b-form-group
          label="Клієнт включений до Переліку осіб стосовно яких застосовано санкції РНБО."
          label-size="lg"
          label-class="font-weight-bold"
        >
          <b-form-group label-cols="3" :horizontal="true">
            <b-form-radio-group
              v-model="form.has_sanctions"
              :options="[
                { text: 'Так', value: true, disabled: true },
                { text: 'Нi', value: false, disabled: true },
              ]"
            />
          </b-form-group>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider vid="sanctions_description" name="коментарі">
        <b-form-group label="Коментарі" label-cols="3" :horizontal="true">
          <b-form-textarea
            rows="3"
            :disabled="!form.has_sanctions"
            type="text"
            v-model="form.sanctions_description"
          />
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        vid="is_aggressor"
        name="клієнт має зв'язки з країною-агресором"
      >
        <b-form-group
          label="Клієнт має зв'язки з країною-агресором."
          label-size="lg"
          label-class="font-weight-bold"
        >
          <b-form-group label-cols="3" :horizontal="true">
            <b-form-radio-group
              v-model="form.is_aggressor"
              :options="[
                { text: 'Так', value: true },
                { text: 'Нi', value: false },
              ]"
            />
          </b-form-group>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider vid="is_aggressor_description" name="коментарі">
        <b-form-group label="Коментарі" label-cols="3" :horizontal="true">
          <b-form-textarea
            rows="6"
            :disabled="!form.is_aggressor"
            type="text"
            v-model="form.is_aggressor_description"
          />
        </b-form-group>
      </ValidationProvider>

      <b-button
        v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
        type="submit"
        size="sm"
        variant="primary"
      >
        <i class="fa fa-dot-circle-o"></i> Зберегти
      </b-button>
    </b-form>
  </ValidationObserver>
</template>

<script>
import PropertyCommanderForm from "../forms/PropertyCommanderForm";
import OwnerForm from "../forms/OwnerForm";
import BeneficialOwnerForm from "../forms/BeneficialOwnerForm";
import SeparateUnitForm from "../forms/SeparateUnitsForm";
import RepresentativeForm from "../forms/RepresentativeForm";
import ParentCompanyForm from "../forms/ParentCompanyForm";
import type from "../mixins/type";
import { mapGetters } from "vuex";

export default {
  props: ["counterpart"],
  name: "ClientDiscovering",
  mixins: [type],
  components: {
    ParentCompanyForm,
    SeparateUnitForm,
    BeneficialOwnerForm,
    OwnerForm,
    PropertyCommanderForm,
    RepresentativeForm,
  },
  data() {
    return {
      form: {
        counterpart_id: null,
        executive_depth: null,
        is_public: null,
        publicity_date: null,
        publicity_type_id: null,
        is_public_description: null,
        public_measures: null,
        is_terrorist: null,
        is_terrorist_description: null,
        has_sanctions: null,
        has_international_sanctions: null,
        sanctions_description: null,
        international_sanctions_description: null,
        is_aggressor: null,
        is_aggressor_description: null,
        has_separate_units: null,
        has_parent_company: null,
        is_nonprofit: null,
        nonprofit_description: null,
        risk_limitation: null,
        other_risk_limitations: null,
        manager_approval_date: null,
        measures_termination_date: null,
      },
      representative: null,
      propertyCommander: null,
      owner: null,
      beneficialOwner: null,
      docDetails: { docType: "", content: "" },
      separateUnitIndexForEditing: null,
      parentCompanyIndexForEditing: null,
      propertyCommandersFields: [
        { key: "name", label: "ПІБ" },
        { key: "position", label: "Посада" },
        { key: "authority_basis", label: "Підстава повноважень" },
        {
          key: "is_public",
          label:
            "Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами",
        },
        { key: "is_terrorist", label: "Належність до терористів" },
        { key: "has_sanctions", label: "Належність до санкцій" },
        this.$auth.can("create counterpart")
          ? { key: "actions", label: "Дії" }
          : null,
      ],
      representativeFields: [
        { key: "name", label: "ПІБ" },
        { key: "position", label: "Посада" },
        { key: "authority_basis", label: "Підстава повноважень" },
        {
          key: "is_public",
          label:
            "Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами",
        },
        { key: "is_terrorist", label: "Належність до терористів" },
        { key: "has_sanctions", label: "Належність до санкцій" },
        this.$auth.can("create counterpart")
          ? { key: "actions", label: "Дії" }
          : null,
      ],
      separateUnitFields: [
        { key: "name", label: "Назва підрозділу" },
        { key: "code", label: "ЄДРПОУ" },
        { key: "is_terrorist", label: "Належність до терористів" },
        { key: "has_sanctions", label: "Належність до санкцій" },
        this.$auth.can("create counterpart")
          ? { key: "actions", label: "Дії" }
          : null,
      ],
      parentCompanyFields: [
        { key: "name", label: "Назва" },
        { key: "code", label: "ЄДРПОУ" },
        { key: "is_terrorist", label: "Належність до терористів" },
        { key: "has_sanctions", label: "Належність до санкцій" },
        this.$auth.can("create counterpart")
          ? { key: "actions", label: "Дії" }
          : null,
      ],
      beneficialOwnerFields: [
        { key: "name", label: "ПІБ" },
        { key: "itn_code", label: "РНОКПП" },
        { key: "influence_basis", label: "Підстава впливу" },
        {
          key: "is_public",
          label:
            "Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами",
        },
        { key: "is_terrorist", label: "Належність до терористів" },
        { key: "has_sanctions", label: "Належність до санкцій" },
        this.$auth.can("create counterpart")
          ? { key: "actions", label: "Дії" }
          : null,
      ],
      ownerFields: [
        { key: "name", label: "ПІБ/Назва" },
        { key: "code", label: "РНОКПП/код за ЄДРПОУ" },
        { key: "address", label: "Адреса реєстрації" },
        { key: "owner_part", label: "Частка у капіталі" },
        {
          key: "is_public",
          label:
            "Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами",
        },
        { key: "is_terrorist", label: "Належність до терористів" },
        { key: "has_sanctions", label: "Належність до санкцій" },
        this.$auth.can("create counterpart")
          ? { key: "actions", label: "Дії" }
          : null,
      ],
    };
  },
  computed: {
    ...mapGetters({
      types: "dictionary/allCounterpartTypes",
      documentTypes: "dictionary/allCounterpartDocumentTypes",
      publicityTypes: "dictionary/allPublicityTypes",
      propertyCommanders: "counterpart/getPropertyCommanders",
      beneficialOwners: "counterpart/getBeneficialOwners",
      owners: "counterpart/getOwners",
      parentCompanies: "counterpart/getParentCompanies",
      separateUnits: "counterpart/getSeparateUnits",
      representatives: "counterpart/getRepresentatives",
    }),
  },
  watch: {
    counterpart: {
      deep: true,
      handler(value) {
        this.form.counterpart_id = value.id;
        this.form.executive_depth = value.clarification.executive_depth;
        this.form.is_public = value.clarification.is_public;
        this.form.publicity_date = value.clarification.publicity_date;
        this.form.publicity_type_id = value.clarification.publicity_type
          ? value.clarification.publicity_type.id
          : null;
        this.form.is_public_description =
          value.clarification.is_public_description;
        this.form.public_measures = value.clarification.public_measures;
        this.form.is_terrorist = value.clarification.is_terrorist;
        this.form.is_terrorist_description =
          value.clarification.is_terrorist_description;
        this.form.has_sanctions = value.clarification.has_sanctions;
        this.form.has_international_sanctions =
          value.clarification.has_international_sanctions;
        this.form.sanctions_description =
          value.clarification.sanctions_description;
        this.form.international_sanctions_description =
          value.clarification.international_sanctions_description;
        this.form.is_aggressor = value.clarification.is_aggressor;
        this.form.is_aggressor_description =
          value.clarification.is_aggressor_description;
        this.form.has_separate_units = value.clarification.has_separate_units;
        this.form.has_parent_company = value.clarification.has_parent_company;
        this.form.is_nonprofit = value.clarification.is_nonprofit;
        this.form.nonprofit_description =
          value.clarification.nonprofit_description;
        this.form.risk_limitation = value.clarification.risk_limitation;
        this.form.other_risk_limitations =
          value.clarification.other_risk_limitations;
        this.form.manager_approval_date =
          value.clarification.manager_approval_date;
        this.form.measures_termination_date =
          value.clarification.measures_termination_date;

        requestAnimationFrame(() => {
          this.$refs.formValidation.reset();
        });
      },
    },
  },
  methods: {
    createPropertyCommander() {
      this.propertyCommander = null;
      this.$root.$emit("bv::show::modal", "modalPropertyCommander");
    },
    editPropertyCommander(index) {
      this.propertyCommander = this.propertyCommanders[index];
      this.$root.$emit("bv::show::modal", "modalPropertyCommander");
    },
    removePropertyCommander(index) {
      if (this.propertyCommanders[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch(
            "counterpart/deletePropertyCommander",
            this.propertyCommanders[index]
          );
      }
    },
    createRepresentative() {
      this.representative = null;
      this.$root.$emit("bv::show::modal", "modalRepresentative");
    },
    editRepresentative(index) {
      this.representative = this.representatives[index];
      this.$root.$emit("bv::show::modal", "modalRepresentative");
    },
    removeRepresentative(index) {
      if (this.representatives[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch(
            "counterpart/deleteRepresentative",
            this.representatives[index]
          );
      }
    },
    createSeparateUnit() {
      this.separateUnitIndexForEditing = null;
      this.$root.$emit("bv::show::modal", "modalSeparateUnit");
    },
    editSeparateUnit(index) {
      this.separateUnitIndexForEditing = index;
      this.$root.$emit("bv::show::modal", "modalSeparateUnit");
    },
    removeSeparateUnit(index) {
      if (this.separateUnits[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch(
            "counterpart/deleteSeparateUnit",
            this.separateUnits[index]
          );
      }
    },
    createParentCompany() {
      this.parentCompanyIndexForEditing = null;
      this.$root.$emit("bv::show::modal", "modalParentCompany");
    },
    editParentCompany(index) {
      this.parentCompanyIndexForEditing = index;
      this.$root.$emit("bv::show::modal", "modalParentCompany");
    },
    removeParentCompany(index) {
      if (this.parentCompanies[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch(
            "counterpart/deleteParentCompany",
            this.parentCompanies[index]
          );
      }
    },
    createBeneficialOwner() {
      this.beneficialOwner = null;
      this.$root.$emit("bv::show::modal", "modalBeneficialOwner");
    },
    editBeneficialOwner(index) {
      this.beneficialOwner = this.beneficialOwners[index];
      this.$root.$emit("bv::show::modal", "modalBeneficialOwner");
    },
    removeBeneficialOwner(index) {
      if (this.beneficialOwners[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch(
            "counterpart/deleteBeneficialOwner",
            this.beneficialOwners[index]
          );
      }
    },
    createOwner() {
      this.owner = null;
      this.$root.$emit("bv::show::modal", "modalOwner");
    },
    editOwner(index) {
      this.owner = this.owners[index];
      this.$root.$emit("bv::show::modal", "modalOwner");
    },
    removeOwner(index) {
      if (this.owners[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch("counterpart/deleteOwner", this.owners[index]);
      }
    },
    onSubmit() {
      this.$refs.formValidation.validate().then((success) => {
        if (!success) {
          this.$snotify.warning("Вказано невірний формат поля");

          return;
        }

        let changedFields = Object.keys(
          this.$refs.formValidation.fields
        ).reduce((accumulator, key) => {
          if (this.$refs.formValidation.fields[key].changed === true) {
            accumulator.push(key);
          }

          return accumulator;
        }, []);

        if (changedFields.length) {
          this.$store.dispatch(
            "counterpart/updateClarification",
            Object.keys(this.form).reduce((accumulator, key) => {
              if (changedFields.includes(key) || key === "counterpart_id") {
                accumulator[key] = this.form[key];
              }

              return accumulator;
            }, {})
          );
        }
      });
    },
    documentDetails(data, modalId) {
      let documentType = "";
      if (data.item.document && data.item.document.type)
        documentType = this.documentTypes.find(
          (type) => type.id === data.item.document.type.id
        ).name;
      this.docDetails =
        "<b>Документ, що посвідчує особу найменування: </b>" +
        documentType +
        "<br>";
      this.docDetails +=
        "<b>Серія та номер: </b>" +
        data.item.document.series +
        " " +
        data.item.document.number +
        "<br>";
      this.docDetails +=
        "<b>Дата видачі: </b>" + data.item.document.issued_date + "<br>";
      this.docDetails +=
        "<b>Ким виданий: </b>" + data.item.document.issued_by + "<br>";
      this.docDetails +=
        "<b>Дата фото: </b>" + data.item.document.photo_date + "<br>";
      if (data.item.document && data.item.document.status) {
        this.docDetails +=
          "<b>Статус: </b>" +
          data.item.document.status.name +
          " " +
          data.item.document.status_date;
      }
      this.$root.$emit("bv::show::modal", modalId);
    },
    removeAllSeparateUnits() {
      if (
        this.separateUnits.length > 0 &&
        this.$auth.can(this.$stringConstants("PERMISSION_CREATE_COUNTERPART"))
      ) {
        if (
          confirm(
            "Ви впевнені що хочете видалити всі записи о відокремлених підрозділах?"
          )
        )
          this.$store.dispatch(
            "counterpart/deleteAllSeparateUnits",
            this.$route.params.id
          );
      }
    },
    removeAllParentCompanies() {
      if (
        this.parentCompanies.length > 0 &&
        this.$auth.can(this.$stringConstants("PERMISSION_CREATE_COUNTERPART"))
      ) {
        if (
          confirm(
            "Ви впевнені що хочете видалити всі записи о материнських компаніях?"
          )
        )
          this.$store.dispatch(
            "counterpart/deleteAllParentCompanies",
            this.$route.params.id
          );
      }
    },
    endedTime(item, type) {
      if (!item || type !== "row") return;
      if (item.ended_at) return "table-secondary";
    },
  },
};
</script>

<style lang="scss">
#representatives-table,
#property-commanders-table {
  .table-secondary {
    color: #0000008a !important;
  }
}
</style>
