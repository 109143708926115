<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-card bg-variant="light">
        <b-form-group
          label-size="lg"
          label-class="font-weight-bold"
          label="Дата встановлення ризику"
        >
          <b-form-input type="date" v-model="form.date" />
        </b-form-group>

        <b-form-group
          v-for="(block, index) in blocks"
          v-bind:key="index"
          label-size="lg"
          label-class="font-weight-bold"
          :label="block.title"
        >
          <b-form-group
            v-if="block.groups"
            v-for="(group, index) in block.groups"
            v-bind:key="index"
            :label="group.title"
          >
            <b-form-checkbox
              v-for="(criterion, index) in group.criteria"
              v-bind:key="index"
              v-model="form[criterion.name]"
              :disabled="criterion.disabled"
            >
              {{ criterion.label }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group v-if="block.criteria">
            <b-form-checkbox
              v-for="(criterion, index) in block.criteria"
              v-bind:key="index"
              v-model="form[criterion.name]"
              :disabled="criterion.disabled"
            >
              {{ criterion.label }}
            </b-form-checkbox>
          </b-form-group>
        </b-form-group>
      </b-card>

      <b-button
        v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
        type="submit"
        :disabled="isLoading"
        variant="success"
        size="sm"
      >
        <i class="fa fa-dot-circle-o"></i> Розрахувати рівень ризику
      </b-button>
    </b-form>
  </ValidationObserver>
</template>

<script>
import storage from "../../../api/api";

export default {
  name: "CrimeRiskLevelForm",
  created() {
    this.isLoading = true;
    storage
      .getCriterionByCounterpart(this.$route.params.id)
      .then(({ data }) => {
        data.forEach(block => {
          if (!block.groups || !(block.groups instanceof Array)) return;
          block.groups.forEach(group => {
            if (!group.criteria || !(group.criteria instanceof Array)) return;
            group.criteria.forEach(criterion => {
              this.$set(this.form, criterion.name, criterion.value);
            });
          });
        });
        this.$set(this, "blocks", data);
        this.isLoading = false;
      });
  },
  data() {
    return {
      form: {
        date: new Date().toISOString().split("T")[0],
        counterpart_id: this.$route.params.id
      },
      blocks: [],
      isLoading: false
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.$store
        .dispatch("counterpart/createCrimeRisk", this.form)
        .then(() => {
          this.isLoading = false;
          this.$root.$emit("bv::hide::modal", "modalCrimeRisk");
        });
    }
  }
};
</script>
