<template>
  <div class="d-flex">
    <b-button
      class="btn-decorate"
      v-if="$auth.can($stringConstants('PERMISSION_PRINT_COUNTERPART'))"
      variant="ghost-info link"
      @click="printCurrentPdfItem(counterpart.id)"
    >
      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
    </b-button>

    <b-button
      class="btn-decorate ml-2"
      v-if="$auth.can($stringConstants('PERMISSION_PRINT_COUNTERPART'))"
      variant="ghost-info link"
      @click="printCurrentDocItem(counterpart.id)"
    >
      <i class="fa fa-file-text-o"></i>
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    counterpart: {
      type: Object,
    },
  },
  methods: {
    printCurrentPdfItem(itemId) {
      this.$store.dispatch("counterparts/print", [itemId, "pdf"]);
    },
    printCurrentDocItem(itemId) {
      this.$store.dispatch("counterparts/print", [itemId, "docx"]);
    },
  },
};
</script>
