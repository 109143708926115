<template>
  <b-form-group
    label="Дані паспорта або іншого документа"
    label-size="lg"
    label-class="font-weight-bold"
  >
    <ValidationProvider
      v-slot="{ errors, dirty }"
      name="тип документа"
      vid="type_id"
    >
      <b-form-group label="Тип документа" label-cols="3" :horizontal="true">
        <b-form-select
          text-field="name"
          value-field="id"
          v-model="form.type_id"
          :options="propDocumentTypes"
          :state="errors.length > 0 ? false : dirty ? true : null"
        />
        <img
          v-if="propDocument && form.status"
          src="/images/text-help.svg"
          alt="help"
          style="position:absolute;width: 35px; left: -35px"
          @mouseover="showInfo = true"
          @mouseleave="showInfo = false"
        />
        <b-form-invalid-feedback
          :state="errors.length === 0"
          v-for="(error, index) in errors"
          v-bind:key="index"
        >
          {{ error }}
        </b-form-invalid-feedback>
        <div
          v-if="propDocument && form.status && showInfo"
          class="text-help__desc text_dedicated"
        >
          {{ form.status.name }} - {{ form.status_date | date }}
        </div>
      </b-form-group>
    </ValidationProvider>

    <component
      v-if="form.type_id"
      :is="componentDocument"
      :prop-document="form"
    />
  </b-form-group>
</template>

<script>
import PassportCardId from "@/components/PassportCardId.vue";
import Passport from "@/components/Passport.vue";
import DefaultDocument from "@/components/DefaultDocument.vue";

export default {
  props: {
    propDocument: {
      type: Object | null,
      required: true
    },
    propDocumentTypes: {
      type: Array,
      required: true
    }
  },
  name: "Document",
  components: {
    PassportCardId,
    Passport,
    DefaultDocument
  },
  data() {
    return {
      submitting: false,
      showInfo: false,
      form: {
        id: null,
        type_id: null,
        number: null,
        series: null,
        birthday: null,
        birthplace: null,
        citizenship: null,
        issued_date: null,
        issued_by: null,
        photo_date: null,
        valid_date: null,
        usdr_code: null,
        status_date: null,
        status: null
      }
    };
  },
  created() {
    for (const [key] of Object.entries(this.form)) {
      if (this.propDocument) {
        if (key === "type_id") {
          this.form[key] = this.propDocument.type.id;
        } else {
          this.form[key] = this.propDocument[key];
        }
      } else {
        this.form[key] = null;
      }
    }
  },
  watch: {
    "form.type_id"(valueType) {
      for (const [key] of Object.entries(this.form)) {
        if (key === "type_id") {
          this.form[key] = valueType;
        } else {
          if (this.propDocument && this.propDocument.type.id === valueType) {
            this.form[key] = this.propDocument[key];
          } else {
            this.form[key] = null;
          }
        }
      }
    },
    propDocument: function() {
      this.form.id = this.propDocument ? this.propDocument.id : null;
    }
  },
  computed: {
    componentDocument: {
      get() {
        let documentType = this.propDocumentTypes.find(
          type => type.id === this.form.type_id
        );

        if (
          documentType &&
          (documentType.value === "passport" ||
            documentType.value === "passportCardId")
        ) {
          return documentType.value;
        } else {
          return "DefaultDocument";
        }
      }
    }
  },
  methods: {}
};
</script>

<style scoped>
.text-help__desc {
  margin: 20px 0px 0px;
}

.text_dedicated {
  font-family: ProximaNova;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #131f3e;
  background-color: #f6f3fc;
  padding: 8px 16px;
  border-radius: 8px;
  width: 100%;
}
</style>
