<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label="Материнська компанія"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="назва"
          :rules="{
            required: true,
            regex: $stringConstants('REGEX_TEXT_NAME'),
          }"
          v-slot="{ errors }"
        >
          <b-form-group label="Назва">
            <b-form-input
              type="text"
              v-model="form.name"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <!-- Країна реєстрації -->
        <ValidationProvider
          v-slot="{ errors }"
          name="країна реєстрації"
          rules="required"
          vid="country_of_registration_id"
        >
          <b-form-group label="Країна реєстрації">
            <b-form-select
              v-model="form.country_of_registration_id"
              :state="errors.length > 0 ? false : null"
              :options="countryList"
              text-field="name.short-name"
              value-field="id"
            >
            </b-form-select>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Країна реєстрації -->

        <ValidationProvider
          name="ЄДРПОУ"
          :rules="{
            required: true,
            codeRegex: codeRegexRule,
          }"
          v-slot="{ errors }"
        >
          <b-form-group label="ЄДРПОУ">
            <b-form-input
              type="text"
              v-model="form.code"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <b-form-group label="Належність до терористів">
          <b-form-radio-group
            stacked
            v-model="form.is_terrorist"
            :options="[
              { text: 'Так', value: true },
              { text: 'Нi', value: false },
            ]"
            :plain="true"
          />
        </b-form-group>

        <b-form-group label="Належність до санкцій">
          <b-form-radio-group
            stacked
            v-model="form.has_sanctions"
            :options="[
              { text: 'Так', value: true },
              { text: 'Нi', value: false },
            ]"
            :plain="true"
          />
        </b-form-group>
      </b-form-group>

      <div slot="footer">
        <b-button
          v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
          type="submit"
          size="sm"
          :variant="index ? 'primary' : 'success'"
        >
          <i class="fa fa-dot-circle-o"></i> {{ index ? "Зберегти" : "Додати" }}
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
export default {
  name: "ParentCompanyForm",
  props: {
    index: null,
  },
  components: { ValidationProvider },
  created() {
    if (this.index >= 0) {
      let form =
        this.$store.getters["counterpart/getParentCompanies"][this.index];
      return this.$set(this, "form", {
        id: form.id,
        name: form.name,
        code: form.code,
        is_terrorist: form.is_terrorist,
        has_sanctions: form.has_sanctions,
        country_of_registration_id: form.country_of_registration?.id,
      });
    }
  },
  data() {
    return {
      form: {
        counterpart_id: this.$route.params.id,
        name: "",
        code: "",
        is_terrorist: false,
        has_sanctions: false,
        country_of_registration_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      countryList: "dictionary/allCountries",
    }),
    codeRegexRule() {
      if (
        this.countryList.find(
          (c) => c.id === this.form?.country_of_registration_id
        )?.value === this.$stringConstants("CODE_UKRAINE")
      ) {
        return "(^[\\d]{8}$)";
      }
      return "^[\\w-]{1,21}$";
    },
  },
  methods: {
    onSubmit() {
      if (this.index === null)
        this.$store.dispatch("counterpart/createParentCompany", this.form);
      else this.$store.dispatch("counterpart/updateParentCompany", this.form);
      this.$root.$emit("bv::hide::modal", "modalParentCompany");
    },
  },
};
</script>
