<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="formValidation">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label="Особа"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="Прізвище"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors, dirty }"
          vid="last_name"
        >
          <b-form-group label="Прізвище">
            <b-form-input
              type="text"
              v-model="form.last_name"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="ім'я"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors, dirty }"
          vid="first_name"
        >
          <b-form-group label="Ім'я">
            <b-form-input
              type="text"
              v-model="form.first_name"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="по батькові"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
          }"
          v-slot="{ errors, dirty }"
          vid="middle_name"
        >
          <b-form-group label="По батькові">
            <b-form-input
              type="text"
              v-model="form.middle_name"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="РНОКПП"
          :rules="{ regex: /^[\d]{10}$/ }"
          v-slot="{ errors, dirty }"
          vid="itn_code"
        >
          <b-form-group label="РНОКПП">
            <b-form-input
              type="text"
              v-model="form.itn_code"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Місце проживання або перебування в Україні"
          v-slot="{ errors, dirty }"
          vid="residence"
        >
          <b-form-group label="Місце проживання або перебування в Україні">
            <b-form-input
              type="text"
              v-model="form.residence"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <b-form-group label="Посада (вказана)">
          <b-form-input type="text" disabled v-model="form.old_position" />
        </b-form-group>
        <ValidationProvider
          name="Посада"
          v-slot="{ errors, dirty }"
          vid="position_id"
          rules="required"
        >
          <b-form-group label="Посада">
            <b-form-select
              text-field="name"
              value-field="id"
              v-model="form.position_id"
              :options="positions"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Підстава повноважень"
          v-slot="{ errors, dirty }"
          vid="authority_basis"
        >
          <b-form-group label="Підстава повноважень">
            <b-form-input
              type="text"
              v-model="form.authority_basis"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Дата початку займання посади"
          v-slot="{ errors, dirty }"
          vid="started_at"
        >
          <b-form-group label="Дата початку займання посади">
            <b-form-input
              type="date"
              v-model="form.started_at"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Дата закінчення займання посади"
          v-slot="{ errors, dirty }"
          vid="ended_at"
        >
          <b-form-group label="Дата закінчення займання посади">
            <b-form-input
              type="date"
              v-model="form.ended_at"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами"
          v-slot="{ errors, dirty }"
          vid="is_public"
        >
          <b-form-group
            label="Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами"
          >
            <b-form-radio-group
              stacked
              v-model="form.is_public"
              :options="[
                { text: 'Так', value: true },
                { text: 'Нi', value: false },
              ]"
              :plain="true"
            >
            </b-form-radio-group>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Належність до терористів"
          v-slot="{ errors, dirty }"
          vid="is_terrorist"
        >
          <b-form-group label="Належність до терористів">
            <b-form-radio-group
              stacked
              v-model="form.is_terrorist"
              :options="[
                { text: 'Так', value: true },
                { text: 'Нi', value: false },
              ]"
              :plain="true"
            >
            </b-form-radio-group>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Належність до санкцій"
          v-slot="{ errors, dirty }"
          vid="has_sanctions"
        >
          <b-form-group label="Належність до санкцій">
            <b-form-radio-group
              stacked
              v-model="form.has_sanctions"
              :options="[
                { text: 'Так', value: true },
                { text: 'Нi', value: false },
              ]"
              :plain="true"
            >
            </b-form-radio-group>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>

      <document
        v-if="propPropertyCommander"
        ref="document"
        :prop-document="
          propPropertyCommander ? propPropertyCommander.document : null
        "
        :prop-document-types="documentTypes"
      ></document>

      <div slot="footer">
        <b-button
          v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
          type="submit"
          size="sm"
          :variant="propPropertyCommander ? 'primary' : 'success'"
        >
          <i class="fa fa-dot-circle-o"></i>
          {{ propPropertyCommander ? "Зберегти" : "Додати" }}
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import Document from "@/components/Document.vue";

export default {
  name: "PropertyCommanderForm",
  components: { Document },
  props: {
    propPropertyCommander: {
      type: Object | null,
      required: true,
    },
  },
  data() {
    return {
      form: {
        id: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        authority_basis: null,
        is_public: false,
        itn_code: null,
        position_id: null,
        residence: null,
        is_terrorist: false,
        has_sanctions: false,
        started_at: null,
        ended_at: null,
        old_position: null,
      },
    };
  },
  created() {
    if (this.propPropertyCommander) {
      this.form.id = this.propPropertyCommander.id;
      this.form.last_name = this.propPropertyCommander.last_name;
      this.form.first_name = this.propPropertyCommander.first_name;
      this.form.middle_name = this.propPropertyCommander.middle_name;
      this.form.authority_basis = this.propPropertyCommander.authority_basis;
      this.form.is_public = this.propPropertyCommander.is_public;
      this.form.itn_code = this.propPropertyCommander.itn_code;
      this.form.position_id = this.propPropertyCommander.position.id || null;
      this.form.residence = this.propPropertyCommander.residence;
      this.form.is_terrorist = this.propPropertyCommander.is_terrorist;
      this.form.has_sanctions = this.propPropertyCommander.has_sanctions;
      this.form.started_at = this.propPropertyCommander.started_at;
      this.form.ended_at = this.propPropertyCommander.ended_at;
      this.form.old_position = this.propPropertyCommander.old_position;
    }
  },
  computed: {
    ...mapGetters({
      documentTypes: "dictionary/allCounterpartDocumentTypes",
      positions: "dictionary/allCounterpartPositions",
    }),
  },
  methods: {
    getChangedDocumentFields() {
      if (this.propPropertyCommander) {
        return Object.keys(this.$refs.formValidation.fields).reduce(
          (accumulator, key) => {
            if (
              this.$refs.formValidation.fields[key].changed === true &&
              key in this.$refs.document.form
            ) {
              accumulator.push(key);
            }

            return accumulator;
          },
          []
        );
      } else {
        return [];
      }
    },
    getChangedPropertyCommanderFields() {
      return Object.keys(this.$refs.formValidation.fields).reduce(
        (accumulator, key) => {
          if (
            this.$refs.formValidation.fields[key].changed === true &&
            key in this.form
          ) {
            accumulator.push(key);
          }

          return accumulator;
        },
        []
      );
    },
    getDocumentFormFields(changedDocumentFields) {
      let fields = Object.keys(this.$refs.document.form).reduce(
        (accumulator, key) => {
          if (changedDocumentFields.includes(key) || key === "id") {
            accumulator[key] = this.$refs.document.form[key];
          }

          return accumulator;
        },
        {}
      );
      fields.property_commander_id = this.form.id;

      return fields;
    },
    onSubmit() {
      let changedDocumentFields = this.getChangedDocumentFields();
      let changedPropertyCommanderFields =
        this.getChangedPropertyCommanderFields();

      new Promise((resolve) => {
        if (changedPropertyCommanderFields.length) {
          let fields = Object.keys(this.form).reduce((accumulator, key) => {
            if (changedPropertyCommanderFields.includes(key) || key === "id") {
              accumulator[key] = this.form[key];
            }

            return accumulator;
          }, {});

          fields.counterpart_id = this.$route.params.id;

          this.$store
            .dispatch(
              this.propPropertyCommander
                ? "counterpart/updatePropertyCommander"
                : "counterpart/createPropertyCommander",
              fields
            )
            .then(() => {
              if (changedDocumentFields.length <= 0) {
                this.$root.$emit("bv::hide::modal", "modalPropertyCommander");
                this.$snotify.success("Оновлено контрагента");
              }
              resolve();
            });
        } else {
          resolve();
        }
      })
        .then(() => {
          if (
            changedDocumentFields.length &&
            this.propPropertyCommander.document &&
            this.$refs.document.form.type_id ===
              this.propPropertyCommander.document.type.id
          ) {
            this.$store
              .dispatch(
                `counterpart/updateDocument`,
                this.getDocumentFormFields(changedDocumentFields)
              )
              .then(() => {
                this.$root.$emit("bv::hide::modal", "modalPropertyCommander");
                this.$snotify.success("Оновлено контрагента");
              });
          }
        })
        .then(() => {
          new Promise((resolve) => {
            if (
              changedDocumentFields.length &&
              this.propPropertyCommander.document &&
              this.propPropertyCommander.document.type.id !==
                this.$refs.document.form.type_id
            ) {
              this.$store
                .dispatch(
                  `counterpart/deleteDocument`,
                  this.propPropertyCommander.document.id
                )
                .then(() => {
                  this.$store
                    .dispatch(
                      `counterpart/createPropertyCommanderDocument`,
                      this.getDocumentFormFields(changedDocumentFields)
                    )
                    .then(() => {
                      this.$root.$emit(
                        "bv::hide::modal",
                        "modalPropertyCommander"
                      );
                      this.$snotify.success("Оновлено контрагента");
                      resolve();
                    });
                });
            } else {
              resolve();
            }
          }).then(() => {
            if (
              this.propPropertyCommander &&
              !this.propPropertyCommander.document &&
              changedDocumentFields.length
            ) {
              this.$store
                .dispatch(
                  `counterpart/createPropertyCommanderDocument`,
                  this.getDocumentFormFields(changedDocumentFields)
                )
                .then(() => {
                  this.$root.$emit("bv::hide::modal", "modalPropertyCommander");
                  this.$snotify.success("Оновлено контрагента");
                });
            }
          });
        });
    },
  },
};
</script>
