<template>
  <div>
    <ValidationProvider
      v-slot="{ errors, dirty }"
      name="Серія документу"
      :rules="{ max: 10 }"
      vid="series"
    >
      <b-form-group label="Серія документу" label-cols="3" :horizontal="true">
        <b-form-input
          type="text"
          v-model="propDocument.series"
          maxlength="10"
          placeholder="AZ"
          :state="errors.length > 0 ? false : dirty ? true : null"
        />
        <b-form-invalid-feedback
          :state="errors.length === 0"
          v-for="(error, index) in errors"
          v-bind:key="index"
        >
          {{ error }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, dirty }"
      name="Номер документу"
      :rules="{ regex: /^[0-9]+$/, max: 50 }"
      vid="number"
    >
      <b-form-group label="Номер документу" label-cols="3" :horizontal="true">
        <b-form-input
          type="text"
          v-model="propDocument.number"
          maxlength="50"
          placeholder="123456789"
          :state="errors.length > 0 ? false : dirty ? true : null"
        />
        <b-form-invalid-feedback
          :state="errors.length === 0"
          v-for="(error, index) in errors"
          v-bind:key="index"
        >
          {{ error }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, dirty }"
      name="дата народження"
      vid="birthday"
    >
      <b-form-group label="Дата народження" label-cols="3" :horizontal="true">
        <b-form-input
          type="date"
          v-model="propDocument.birthday"
          :state="errors.length > 0 ? false : dirty ? true : null"
        />
        <b-form-invalid-feedback
          :state="errors.length === 0"
          v-for="(error, index) in errors"
          v-bind:key="index"
        >
          {{ error }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, dirty }"
      name="Місце народження"
      :rules="{ max: 255 }"
      vid="birthplace"
    >
      <b-form-group label="Місце народження" label-cols="3" :horizontal="true">
        <b-form-input
          type="text"
          v-model="propDocument.birthplace"
          maxlength="255"
          :state="errors.length > 0 ? false : dirty ? true : null"
        />
        <b-form-invalid-feedback
          :state="errors.length === 0"
          v-for="(error, index) in errors"
          v-bind:key="index"
        >
          {{ error }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, dirty }"
      name="громадянство"
      :rules="{ regex: /^[А-Яа-яёЁЇїІіЄєҐґэЭ'ʼ]{3,25}$/, max: 255 }"
      vid="citizenship"
    >
      <b-form-group label="Громадянство" label-cols="3" :horizontal="true">
        <b-form-input
          type="text"
          v-model="propDocument.citizenship"
          maxlength="255"
          :state="errors.length > 0 ? false : dirty ? true : null"
        />
        <b-form-invalid-feedback
          :state="errors.length === 0"
          v-for="(error, index) in errors"
          v-bind:key="index"
        >
          {{ error }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, dirty }"
      name="дата видачі"
      vid="issued_date"
    >
      <b-form-group label="Дата видачі" label-cols="3" :horizontal="true">
        <b-form-input
          type="date"
          v-model="propDocument.issued_date"
          :state="errors.length > 0 ? false : dirty ? true : null"
        />
        <b-form-invalid-feedback
          :state="errors.length === 0"
          v-for="(error, index) in errors"
          v-bind:key="index"
        >
          {{ error }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, dirty }"
      name="ким виданий"
      rules="max:255"
      vid="issued_by"
    >
      <b-form-group label="Ким виданий" label-cols="3" :horizontal="true">
        <b-form-input
          type="text"
          maxlength="255"
          v-model="propDocument.issued_by"
          :state="errors.length > 0 ? false : dirty ? true : null"
        />
        <b-form-invalid-feedback
          :state="errors.length === 0"
          v-for="(error, index) in errors"
          v-bind:key="index"
        >
          {{ error }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, dirty }"
      name="унікальний номер запису в єдиному демографічному реєстрі"
      :rules="{ regex: /^[\d]{8}-[\d]{5}$/ }"
      vid="usdr_code"
    >
      <b-form-group
        label="Унікальний номер запису в єдиному демографічному реєстрі"
        label-cols="3"
        :horizontal="true"
      >
        <b-form-input
          type="text"
          placeholder="00000000-00000"
          maxlength="14"
          v-model="propDocument.usdr_code"
          :state="errors.length > 0 ? false : dirty ? true : null"
        />
        <b-form-invalid-feedback
          :state="errors.length === 0"
          v-for="(error, index) in errors"
          v-bind:key="index"
        >
          {{ error }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, dirty }"
      name="дата дії документу"
      vid="valid_date"
    >
      <b-form-group
        label="Дата дії документу"
        label-cols="3"
        :horizontal="true"
      >
        <b-form-input
          type="date"
          v-model="propDocument.valid_date"
          :state="errors.length > 0 ? false : dirty ? true : null"
        />
        <b-form-invalid-feedback
          :state="errors.length === 0"
          v-for="(error, index) in errors"
          v-bind:key="index"
        >
          {{ error }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors, dirty }"
      name="дата фото"
      vid="photo_date"
    >
      <b-form-group label="Дата фото" label-cols="3" :horizontal="true">
        <b-form-input
          type="date"
          v-model="propDocument.photo_date"
          :state="errors.length > 0 ? false : dirty ? true : null"
        />
        <b-form-invalid-feedback
          :state="errors.length === 0"
          v-for="(error, index) in errors"
          v-bind:key="index"
        >
          {{ error }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>
<script>
export default {
  props: {
    propDocument: {
      type: Object,
      required: true
    }
  },
  name: "DefaultDocument"
};
</script>
