<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group label="Види діяльності" label-cols="3" :horizontal="true">
        <multiselect
          v-model="activities"
          :multiple="true"
          track-by="id"
          :options="activityList"
          :custom-label="customActivityLabel"
          placeholder="Вибір діяльності"
        />
      </b-form-group>

      <b-form-group label="Зміст діяльності" label-cols="3" :horizontal="true">
        <b-form-input type="text" v-model="activity_content" />
      </b-form-group>

      <!-- Види послуг, якими користується клієнт -->
      <ValidationProvider
        v-if="isIndividual(counterpart ? counterpart.type : null)"
        v-slot="{ errors, dirty }"
        name="Види послуг, якими користується клієнт"
        rules="max:255"
      >
        <b-form-group
          label="Види послуг, якими користується клієнт"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input
            type="text"
            v-model="client_services"
            :state="errors.length > 0 ? false : null"
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <!-- END: Види послуг, якими користується клієнт -->

      <b-form-group label="Веб-сайт" label-cols="3" :horizontal="true">
        <b-form-input type="text" v-model="site" />
      </b-form-group>

      <b-form-group
        label="Мета та характер ділових відносин"
        label-cols="3"
        :horizontal="true"
      >
        <b-form-input type="text" v-model="business_purpose" />
      </b-form-group>

      <ValidationProvider
        name="Кількість працівників"
        :rules="{ required: false, regex: /^\d{1,5}?$/ }"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Кількість працівників"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input type="text" v-model="staff_number" />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <b-form-group
        v-if="isLegal(counterpart ? counterpart.type : null)"
        label="Статутний капітал"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="Зареєстрований"
          :rules="{ required: false, regex: /^\d+(\.\d{1,2})?$/ }"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Зареєстрований"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              placeholder="0.00"
              v-model="fund_registered"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Сплачений"
          :rules="{ required: false, regex: /^\d+(\.\d{1,2})?$/ }"
          v-slot="{ errors }"
        >
          <b-form-group label="Сплачений" label-cols="3" :horizontal="true">
            <b-form-input
              v-model="fund_paid"
              placeholder="0.00"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Заходи щодо здійснення поглибленої перевірки"
        label-cols="3"
        :horizontal="true"
      >
        <b-form-input
          v-model="depth_examination"
          placeholder="Не здійснювались"
        />
      </b-form-group>

      <b-button
        v-if="$auth.can('create counterpart')"
        :disabled="submitting"
        type="submit"
        size="sm"
        variant="primary"
      >
        <i class="fa fa-dot-circle-o"></i> Зберегти
      </b-button>
    </b-form>

    <br />

    <FinancialAssessment />

    <b-form-group
      label="Ліцензії, дозволи на право здійснення певних операцій"
      label-size="lg"
      label-class="font-weight-bold"
    >
      <b-table
        :fields="licensesFields"
        :hover="true"
        :outlined="true"
        :fixed="true"
        :small="true"
        :items="licenses"
      >
        <template
          v-slot:cell(actions)="data"
          v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
        >
          <b-button
            variant="ghost-info link"
            @click="editLicense(data.index)"
            size="sm"
          >
            <i class="fa fa-edit" />
          </b-button>
          <b-button
            variant="link text-danger"
            size="sm"
            @click="removeLicense(data.index)"
          >
            <i class="fa fa-close" />
          </b-button>
        </template>
      </b-table>

      <b-button
        v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
        variant="link text-success"
        size="sm"
        @click="createLicense()"
        class="mb-2"
      >
        <i class="fa fa-plus" />
      </b-button>

      <b-modal id="modalLicenses" hide-header hide-footer size="lg">
        <license-form :index="licenseIndexForEditing" />
      </b-modal>
    </b-form-group>

    <b-form-group
      label="Рівень ризику проведення фінансових операцій з легалізації кримінальних доходів/фінансування тероризму."
      label-size="lg"
      label-class="font-weight-bold"
    >
      <b-table
        :fields="crimeRiskFields"
        :hover="true"
        :outlined="true"
        :fixed="true"
        :small="true"
        :items="crimeRisks"
      >
        <template v-slot:cell(actions)="data">
          <b-button
            v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
            variant="link text-danger"
            size="sm"
            @click="removeCrimeRiskLevel(data.index)"
          >
            <i class="fa fa-close" />
          </b-button>
        </template>
      </b-table>

      <b-button
        v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
        variant="link text-success"
        size="sm"
        @click="createCrimeRiskLevel()"
        class="mb-2"
      >
        <i class="fa fa-plus" />
      </b-button>
      <b-modal id="modalCrimeRisk" hide-header hide-footer size="lg">
        <crime-risk-form :index="crimeRiskLevelIndexForEditing" />
      </b-modal>
    </b-form-group>
  </ValidationObserver>
</template>

<script>
import Multiselect from "vue-multiselect";
import LicenseForm from "../forms/LicenseForm";
import CrimeRiskForm from "../forms/CrimeRiskForm";
import FinancialAssessment from "./RiskManagement/FinancialAssessment";
import { mapGetters } from "vuex";
import "../../../directives/commonDirectives";
import type from "../mixins/type";

export default {
  components: {
    Multiselect,
    LicenseForm,
    CrimeRiskForm,
    FinancialAssessment
  },
  name: "RiskManagement",
  mixins: [type],
  data() {
    return {
      submitting: false,
      licensesFields: [
        { key: "number", label: "Серія та номер ліцензії/дозволу" },
        { key: "issued_by", label: "Ким виданий" },
        { key: "validity_date", label: "Термін дії" },
        this.$auth.can("create counterpart")
          ? { key: "actions", label: "Дії" }
          : null
      ],
      licenseIndexForEditing: null,
      crimeRiskFields: [
        { key: "date", label: "Дата встановлення ризику" },
        {
          key: "risk_level",
          label: "Рівень ризику",
          formatter: item => {
            if (item === null) return "";
            return this.crimeRiskLevels.find(
              level => level.value === item.value
            ).name;
          }
        },
        { key: "comment", label: "Комментар" },
        this.$auth.can("create counterpart")
          ? { key: "actions", label: "Дії" }
          : null
      ],
      crimeRiskLevelIndexForEditing: null
    };
  },
  computed: {
    ...mapGetters({
      types: "dictionary/allCounterpartTypes",
      taxSystems: "dictionary/allCounterpartTaxSystems",
      activityList: "dictionary/allCounterpartActivities",
      crimeRiskLevels: "dictionary/allCounterpartCrimeRiskLevels",
      form: "counterpart/getRiskManagement",
      counterpart: "counterpart/get"
    }),
    crimeRisks: function() {
      return this.$store.getters["counterpart/getCrimeRisks"];
    },
    licenses: function() {
      return this.$store.getters["counterpart/getLicenses"];
    },
    activities: {
      get() {
        if (!this.form || !this.form.activities) return [];
        return this.form.activities;
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "activities",
          value
        });
      }
    },
    activity_content: {
      get() {
        return this.form ? this.form.activity_content : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "activity_content",
          value
        });
      }
    },
    client_services: {
      get() {
        return this.form ? this.form.client_services : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "client_services",
          value
        });
      }
    },
    site: {
      get() {
        return this.form ? this.form.site : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "site",
          value
        });
      }
    },
    business_purpose: {
      get() {
        return this.form ? this.form.business_purpose : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "business_purpose",
          value
        });
      }
    },
    staff_number: {
      get() {
        return this.form ? this.form.staff_number : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "staff_number",
          value
        });
      }
    },
    fund_registered: {
      get() {
        return this.form ? this.form.fund_registered : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "fund_registered",
          value
        });
      }
    },
    fund_paid: {
      get() {
        return this.form ? this.form.fund_paid : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "fund_paid",
          value
        });
      }
    },
    projected_amount: {
      get() {
        return this.form ? this.form.projected_amount : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "projected_amount",
          value
        });
      }
    },
    net_profit: {
      get() {
        return this.form ? this.form.net_profit : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "net_profit",
          value
        });
      }
    },
    revenue: {
      get() {
        return this.form ? this.form.revenue : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "revenue",
          value
        });
      }
    },
    depth_examination: {
      get() {
        return this.form ? this.form.depth_examination : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "depth_examination",
          value
        });
      }
    },
    tax_system_id: {
      get() {
        if (!this.form) return "";
        if (!this.form.tax_system) return "";
        return this.form.tax_system.id;
      },
      set(value) {
        const taxSystem = this.taxSystems.find(system => system.id === value);
        this.$store.commit("counterpart/updateRiskManagementField", {
          key: "tax_system",
          value: taxSystem
        });
      }
    },
    submissionForm: function() {
      return {
        counterpart_id: this.$route.params.id,
        activity_content: this.activity_content,
        business_purpose: this.business_purpose,
        depth_examination: this.depth_examination,
        fund_paid: this.fund_paid,
        fund_registered: this.fund_registered,
        net_profit: this.net_profit,
        projected_amount: this.projected_amount,
        revenue: this.revenue,
        site: this.site,
        staff_number: this.staff_number,
        tax_system_id: this.tax_system_id,
        client_services: this.client_services,
        activities: this.activities.map(activity => activity.id)
      };
    }
  },
  methods: {
    customActivityLabel({ name, value }) {
      return `${value}  ${name}`;
    },
    createLicense() {
      this.licenseIndexForEditing = null;
      this.$root.$emit("bv::show::modal", "modalLicenses");
    },
    editLicense(index) {
      this.licenseIndexForEditing = index;
      this.$root.$emit("bv::show::modal", "modalLicenses");
    },
    removeLicense(index) {
      if (this.licenses[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch(
            "counterpart/deleteLicense",
            this.licenses[index]
          );
      }
    },
    createCrimeRiskLevel() {
      this.crimeRiskLevelIndexForEditing = null;
      this.$root.$emit("bv::show::modal", "modalCrimeRisk");
    },
    removeCrimeRiskLevel(index) {
      if (this.crimeRisks[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch(
            "counterpart/deleteCrimeRisk",
            this.crimeRisks[index]
          );
      }
    },
    onSubmit() {
      this.submitting = true;
      this.$store
        .dispatch("counterpart/updateRiskManagement", this.submissionForm)
        .then(() => (this.submitting = false))
        .catch(() => (this.submitting = false));
    }
  }
};
</script>
