<template>
  <b-form-group
    label="Верифікація контрагента"
    label-size="lg"
    label-class="font-weight-bold"
    class="mt-5"
  >
    <b-table
      :fields="verificationFields"
      :hover="true"
      :outlined="true"
      :fixed="true"
      :small="true"
      :items="verifications"
    >
      <template v-slot:cell(verified_at)="data">
        {{ data.item.verified_at | date }}
      </template>

      <template v-slot:cell(method)="data">
        <div class="d-flex align-items-center">
          {{ data.item.method.name }}
          <b-icon-info-circle
            class="mx-2"
            v-if="data.item.payload"
            @click="iseiData = data.item.payload"
            v-b-modal.isei-verification
          />
          <span class="ml-2 badge badge-success"
                v-if="data.item.id === profileMaintain.verification_id"
          >
            актуально
          </span>
        </div>
      </template>
    </b-table>

    <b-button
      v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
      variant="link text-success"
      size="sm"
      @click="createVerification()"
      class="mb-2"
    >
      <i class="fa fa-plus" />
    </b-button>

    <CreateVerification />

    <IseiDataModal :isei-data="iseiData" />

  </b-form-group>
</template>

<script>
import mixins from "@/mixins";
import { mapGetters } from "vuex";
import CreateVerification from "@/entity/Counterparts/parts/Verification/CreateVerification.vue";
import IseiDataModal from "@/entity/Counterparts/parts/Verification/IseiDataModal.vue";

export default {
  name: "VerificationList",
  components: { IseiDataModal, CreateVerification },
  mixins: [mixins],
  data() {
    return {
      removeVerificationIndex: null,
      iseiData: {},
      verificationFields: [
        {
          key: "verified_at",
          label: "Дата верифікації",
        },
        {
          key: "method",
          label: "Метод верифікації",
          tdClass: "align-middle",
          formatter: (item) => {
            if (item === null) return "";
            return this.verificationMethods.find(
              (verify) => verify.value === item.value
            ).name;
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      verificationMethods: "dictionary/allCounterpartVerificationMethods",
      profileMaintain: "counterpart/getProfileMaintain",
    }),
    verifications: function () {
      return this.$store.getters["counterpart/getVerifications"];
    },
  },
  methods: {
    createVerification() {
      this.$bvModal.show("create-verification-modal");
    },
  },
};
</script>
