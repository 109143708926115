<template>
  <b-form>
    <b-form-group
      label-size="lg"
      label-class="font-weight-bold"
      label="Оцінка відповідності фін.операцій клієнта змісту його діяльності та у
            разі невідповідності – вжиті заходи щодо з’ясування суті та мети проведення фін.операцій"
    >
      <b-table
        :fields="activityEvaluationFields"
        :hover="true"
        :outlined="true"
        :fixed="true"
        :small="true"
        :items="activity_evaluations"
      >
        <template v-slot:cell(actions)="data">
          <b-button
            variant="ghost-info link"
            @click="editActivity(data.index)"
            size="sm"
            alt="Редагувати"
          >
            <i class="fa fa-edit" />
          </b-button>
          <b-button
            variant="link text-danger"
            size="sm"
            @click="removeActivity(data.index)"
            alt="Видалити"
          >
            <i class="fa fa-close" />
          </b-button>
        </template>
      </b-table>

      <b-button
        v-if="$auth.can('create counterpart')"
        variant="link text-success"
        size="sm"
        @click="createActivity()"
        class="mb-2"
      >
        <i class="fa fa-plus" />
      </b-button>
      <b-modal id="modalActivityEvaluation" hide-header hide-footer size="lg">
        <activity-evaluation-form :index="activityEvaluationIndexForEditing" />
      </b-modal>
    </b-form-group>
    <b-form-group
      label-size="lg"
      label-class="font-weight-bold"
      label="Оцінка відповідності фін.операцій клієнта його фінансовому стану та у разі
                      невідповідності – вжиті заходи щодо з’ясування джерел походження коштів"
    >
      <b-table
        :fields="stateEvaluationFields"
        :hover="true"
        :outlined="true"
        :fixed="true"
        :small="true"
        :items="state_evaluations"
      >
        <template v-slot:cell(actions)="data">
          <b-button
            variant="ghost-info link"
            @click="editState(data.index)"
            size="sm"
            alt="Редагувати"
          >
            <i class="fa fa-edit" />
          </b-button>
          <b-button
            variant="link text-danger"
            size="sm"
            @click="removeState(data.index)"
            alt="Видалити"
          >
            <i class="fa fa-close" />
          </b-button>
        </template>
      </b-table>

      <b-button
        v-if="$auth.can('create counterpart')"
        variant="link text-success"
        size="sm"
        @click="createState()"
        class="mb-2"
      >
        <i class="fa fa-plus" />
      </b-button>
      <b-modal id="modalStateEvaluation" hide-header hide-footer size="lg">
        <state-evaluation-form :index="stateEvaluationIndexForEditing" />
      </b-modal>
    </b-form-group>
  </b-form>
</template>

<script>
import { mapGetters } from "vuex";
import ActivityEvaluationForm from "../forms/ActivityEvaluationForm";
import StateEvaluationForm from "../forms/StateEvaluationForm";

export default {
  name: "Conformity",
  components: { ActivityEvaluationForm, StateEvaluationForm },
  data() {
    return {
      activityEvaluationIndexForEditing: null,
      activityEvaluationFields: [
        { key: "date_at", label: "Дата оцінки" },
        {
          key: "evaluation_result",
          label: "Результат оцінки",
          formatter: evaluationResult =>
            this.activityEvaluations.find(
              item => item.id === evaluationResult.id
            ).name
        },
        { key: "start_at", label: "Початок періоду" },
        { key: "end_at", label: "Кінець періоду" },
        this.$auth.can("create counterpart")
          ? { key: "actions", label: "Дії" }
          : null
      ],
      stateEvaluationIndexForEditing: null,
      stateEvaluationFields: [
        { key: "date_at", label: "Дата оцінки" },
        {
          key: "evaluation_result",
          label: "Результат оцінки",
          formatter: evaluationResult =>
            this.stateEvaluations.find(item => item.id === evaluationResult.id)
              .name
        },
        { key: "start_at", label: "Початок періоду" },
        { key: "end_at", label: "Кінець періоду" },
        this.$auth.can("create counterpart")
          ? { key: "actions", label: "Дії" }
          : null
      ]
    };
  },
  computed: {
    ...mapGetters({
      activityEvaluations: "dictionary/allCounterpartActivityEvaluations",
      stateEvaluations: "dictionary/allCounterpartStateEvaluations"
    }),
    activity_evaluations: function() {
      return this.$store.getters["counterpart/getActivityEvaluations"];
    },
    state_evaluations: function() {
      return this.$store.getters["counterpart/getStateEvaluations"];
    }
  },
  methods: {
    createActivity() {
      this.activityEvaluationIndexForEditing = null;
      this.$root.$emit("bv::show::modal", "modalActivityEvaluation");
    },
    editActivity(index) {
      this.activityEvaluationIndexForEditing = index;
      this.$root.$emit("bv::show::modal", "modalActivityEvaluation");
    },
    removeActivity(index) {
      if (this.activity_evaluations[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch(
            "counterpart/deleteActivityEvaluation",
            this.activity_evaluations[index]
          );
      }
    },
    createState() {
      this.stateEvaluationIndexForEditing = null;
      this.$root.$emit("bv::show::modal", "modalStateEvaluation");
    },
    editState(index) {
      this.stateEvaluationIndexForEditing = index;
      this.$root.$emit("bv::show::modal", "modalStateEvaluation");
    },
    removeState(index) {
      if (this.state_evaluations[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch(
            "counterpart/deleteStateEvaluation",
            this.state_evaluations[index]
          );
      }
    }
  }
};
</script>
