<template>
  <ValidationObserver
    v-slot="{ handleSubmit, invalid, dirty }"
    ref="formValidation"
  >
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        label="Основні дані"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="статус ділових відносин"
          v-slot="{ errors, dirty }"
          vid="status_id"
        >
          <b-form-group
            label="Статус ділових відносин"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-select
              text-field="name"
              value-field="id"
              v-model="form.status_id"
              :options="statuses"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="тип контрагента"
          rules="required"
          v-slot="{ errors, dirty }"
          vid="type"
        >
          <b-form-group
            label="Тип контрагента"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-select
              text-field="name"
              value-field="id"
              v-model="form.type"
              :options="types"
              :state="errors.length > 0 ? false : dirty ? true : null"
              disabled
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="профіль СПФМ"
          rules="required"
          v-slot="{ errors }"
          vid="financial_model_id"
        >
          <b-form-group label="Профіль СПФМ" label-cols="3" :horizontal="true">
            <b-form-select
              v-model="form.financial_model_id"
              :options="financialModels"
              value-field="id"
              text-field="name"
              :disabled="true"
            />
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="організаційно-правова форма"
          v-slot="{ errors, dirty }"
        >
          <b-form-group
            label="Організаційно-правова форма"
            label-cols="3"
            :horizontal="true"
            vid="legal_form_id"
          >
            <b-form-select
              text-field="name"
              value-field="id"
              v-model="form.legal_form_id"
              :options="legalForms"
              :state="errors.length > 0 ? false : dirty ? true : null"
              disabled
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="повне найменування"
          v-slot="{ errors, dirty }"
          v-if="
            !isIndividual(counterpart.type) &&
            !isIndividualEntrepreneur(counterpart.type) &&
            !isNonResidentIndividual(counterpart.type)
          "
          vid="full_legal_name"
        >
          <b-form-group
            label="Повне найменування"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.full_legal_name"
              :state="errors.length > 0 ? false : dirty ? true : null"
              disabled
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="скорочене найменування"
          v-slot="{ errors, dirty }"
          v-if="
            !isIndividual(counterpart.type) &&
            !isIndividualEntrepreneur(counterpart.type) &&
            !isNonResidentIndividual(counterpart.type)
          "
          vid="short_legal_name"
        >
          <b-form-group
            label="Скорочене найменування"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.short_legal_name"
              :state="errors.length > 0 ? false : dirty ? true : null"
              disabled
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="найменування"
          v-slot="{ errors, dirty }"
          v-if="
            !isIndividual(counterpart.type) &&
            !isIndividualEntrepreneur(counterpart.type) &&
            !isNonResidentIndividual(counterpart.type)
          "
          :rules="{
            regex: $stringConstants('REGEX_TEXT_NAME'),
          }"
          vid="name"
        >
          <b-form-group label="Найменування" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              v-model="form.name"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          v-if="
            isIndividual(counterpart.type) ||
            isIndividualEntrepreneur(counterpart.type) ||
            isNonResidentIndividual(counterpart.type)
          "
          name="Прізвище"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors, dirty }"
          vid="last_name"
        >
          <b-form-group label="Прізвище" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              v-model="form.last_name"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-if="
            isIndividual(counterpart.type) ||
            isIndividualEntrepreneur(counterpart.type) ||
            isNonResidentIndividual(counterpart.type)
          "
          name="ім'я"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors, dirty }"
          vid="first_name"
        >
          <b-form-group label="Ім'я" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              v-model="form.first_name"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-if="
            isIndividual(counterpart.type) ||
            isIndividualEntrepreneur(counterpart.type) ||
            isNonResidentIndividual(counterpart.type)
          "
          name="по батькові"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: !isNonResidentIndividual(counterpart.type),
          }"
          v-slot="{ errors, dirty }"
          vid="middle_name"
        >
          <b-form-group label="По батькові" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              v-model="form.middle_name"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-if="
            isLegal(counterpart.type) || isNonResidentLegal(counterpart.type)
          "
          v-slot="{ errors, dirty }"
          name="форма власності"
          vid="property_form_id"
        >
          <b-form-group
            label="Форма власності"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-select
              text-field="name"
              value-field="id"
              v-model="form.property_form_id"
              :options="propertyForms"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          v-if="isLegal(counterpart.type)"
          name="ЄДРПОУ"
          :rules="{ regex: /^[\d]{8}$/ }"
          vid="usreou_code"
        >
          <b-form-group label="ЄДРПОУ" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              placeholder="8-ми значний номер"
              v-model="form.usreou_code"
              :state="errors.length > 0 ? false : dirty ? true : null"
              disabled
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          v-if="isNonResident(counterpart.type)"
          :rules="{
            regex: /^[\w\d\sА-Яа-яёЁЇїІіЄєҐґэЭ!-_.\/()<>#%&@*+'?]{3,30}$/,
            max: 30,
          }"
          name="Код нерезидента"
          vid="non_resident_code"
        >
          <b-form-group
            label="Код нерезидента"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.non_resident_code"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-if="
            isIndividualEntrepreneur(counterpart.type) ||
            isIndividual(counterpart.type)
          "
          v-slot="{ errors, dirty }"
          name="РНОКПП"
          :rules="{ regex: /^[\d]{10}$/ }"
          vid="itn_code"
        >
          <b-form-group label="РНОКПП" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              placeholder="10-ти значний номер"
              v-model="form.itn_code"
              :state="errors.length > 0 ? false : dirty ? true : null"
              disabled
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="телефон"
          :rules="{ regex: /^\+[\d]{12}$/ }"
          vid="phone"
        >
          <b-form-group label="Телефон" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              placeholder="+380440000000"
              v-model="form.phone"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="адреса електронної пошти"
          rules="email"
          vid="email"
        >
          <b-form-group
            label="Адреса електронної пошти"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.email"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="юридичний статус"
          v-slot="{ errors, dirty }"
          vid="state_id"
        >
          <b-form-group
            label="Юридичний статус"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-select
              text-field="name"
              value-field="id"
              v-model="form.state_id"
              :options="states"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами"
          v-slot="{ errors, dirty }"
          vid="is_public"
        >
          <b-form-group
            v-if="
              !isLegal(counterpart.type) &&
              !isNonResidentLegal(counterpart.type)
            "
            label="Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-group>
              <b-form-radio-group
                v-model="form.is_public"
                :options="[
                  { text: 'Так', value: true },
                  { text: 'Нi', value: false },
                ]"
              />
            </b-form-group>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Належність до терористів"
          v-slot="{ errors, dirty }"
          vid="is_terrorist"
        >
          <b-form-group
            label="Належність до терористів"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-group>
              <b-form-radio-group
                v-model="form.is_terrorist"
                :options="[
                  { text: 'Так', value: true },
                  { text: 'Нi', value: false },
                ]"
              />
            </b-form-group>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Належність до санкцій"
          v-slot="{ errors, dirty }"
          vid="has_sanctions"
        >
          <b-form-group
            label="Належність до санкцій"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-group>
              <b-form-radio-group
                v-model="form.has_sanctions"
                :options="[
                  { text: 'Так', value: true },
                  { text: 'Нi', value: false },
                ]"
              />
            </b-form-group>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <document
        ref="document"
        :prop-document="counterpart.document"
        :prop-document-types="documentTypes"
        v-if="
          (isIndividualEntrepreneur(counterpart.type) ||
            isIndividual(counterpart.type) ||
            isNonResidentIndividual(counterpart.type)) &&
          !loadCounterpart
        "
      ></document>
      <b-form-group
        v-if="!isNonResidentLegal(counterpart.type)"
        label="Дата та номер запису в ЄДРПОУ про проведення державної реєстрації"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="дата запису"
          vid="state_registration_date"
        >
          <b-form-group label="Дата запису" label-cols="3" :horizontal="true">
            <b-form-input
              type="date"
              v-model="form.state_registration_date"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="номер запису"
          :rules="{ regex: /^(\d{17}(\d{2})?)$/ }"
          vid="state_registration_number"
        >
          <b-form-group label="Номер запису" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              placeholder="17/19-ти значний номер"
              v-model="form.state_registration_number"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
        v-if="isNonResidentLegal(counterpart.type)"
        label="Дані про проведення реєстрації"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="Країна реєстрації"
          vid="country_id"
        >
          <b-form-group
            label="Країна реєстрації"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-select
              v-model="form.country_id"
              :state="errors.length > 0 ? false : dirty ? true : null"
            >
              <option value=""></option>
              <option
                v-for="option in countryList"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name["short-name"] }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="дата запису"
          vid="state_registration_date"
        >
          <b-form-group label="Дата запису" label-cols="3" :horizontal="true">
            <b-form-input
              type="date"
              v-model="form.state_registration_date"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="Орган реєстрації"
          :rules="{
            regex: /^[\w\d\sА-Яа-яёЁЇїІіЄєҐґэЭ!-_.\/()<>#%&@*+'?]{1,255}$/,
            max: 255,
          }"
          vid="registration_authority"
        >
          <b-form-group
            label="Орган реєстрації"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.registration_authority"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="Реквізити свідоцтва про реєстрацію"
          :rules="{
            regex: /^[\w\d\sА-Яа-яёЁЇїІіЄєҐґэЭ!-_.\/()<>#%&@*+'?]{1,255}$/,
            max: 255,
          }"
          vid="registration_certificate"
        >
          <b-form-group
            label="Реквізити свідоцтва про реєстрацію"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.registration_certificate"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
        label="Місцезнаходження"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="індекс"
          rules="digits:5"
          vid="postal_code"
        >
          <b-form-group label="Індекс" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              v-model="form.postal_code"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="населений пункт"
          rules="max:255"
          vid="city"
        >
          <b-form-group
            label="Населений пункт"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.city"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="адреса"
          rules="max:255"
          vid="address"
        >
          <b-form-group label="Адреса" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              v-model="form.address"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-if="
            isIndividual(counterpart.type) ||
            isNonResidentIndividual(counterpart.type)
          "
          v-slot="{ errors, dirty }"
          name="Місце тимчасового перебування на території України (для нерезидентів)"
          rules="max:255"
          vid="temporary_stay"
        >
          <b-form-group
            label="Місце тимчасового перебування на території України (для нерезидентів)"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.temporary_stay"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-if="
            isIndividual(counterpart.type) ||
            isNonResidentIndividual(counterpart.type)
          "
          v-slot="{ errors, dirty }"
          name="Місце роботи, посада"
          rules="max:255"
          vid="work"
        >
          <b-form-group
            label="Місце роботи, посада"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.work"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
        label="Реквізити банку, в якому відкрито рахунок, і номер поточного рахунка."
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="код МФО"
          :rules="{ regex: /^[\d]{6}$/ }"
          vid="mfo"
        >
          <b-form-group label="Код МФО" label-cols="3" :horizontal="true">
            <b-form-input
              placeholder="6-ти значний номер"
              type="text"
              v-model="form.mfo"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="назва банку"
          rules="max:255"
          vid="bank"
        >
          <b-form-group label="Назва банку" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              v-model="form.bank"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="номер рахунку"
          :rules="{ regex: /^[A-Z]{2}[\d]{27}$/ }"
          vid="iban"
        >
          <b-form-group label="Номер рахунку" label-cols="3" :horizontal="true">
            <b-form-input
              type="text"
              placeholder="Номер рахунку в Iban форматі"
              v-model="form.iban"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
        label="Документи"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <b-form-group
          label="Завантажити документи"
          label-cols="3"
          :horizontal="true"
          label-for="documents"
        >
          <uploader :name="'counterpartsDos'" :id="$route.params.id"></uploader>
        </b-form-group>
      </b-form-group>

      <b-button
        v-if="$auth.can('create counterpart')"
        type="submit"
        size="sm"
        variant="primary"
      >
        <i class="fa fa-dot-circle-o"></i> Зберегти
      </b-button>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import Uploader from "../../../components/Uploader.vue";
import typeMixin from "../mixins/type";
import Document from "@/components/Document";

export default {
  props: ["counterpart", "loadCounterpart"],
  name: "identifierData",
  mixins: [typeMixin],
  components: {
    Document,
    Uploader,
  },
  data() {
    return {
      form: {
        id: null,
        status_id: null,
        type: null,
        financial_model_id: null,
        legal_form_id: null,
        full_legal_name: null,
        short_legal_name: null,
        name: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        property_form_id: null,
        usreou_code: null,
        non_resident_code: null,
        itn_code: null,
        phone: null,
        email: null,
        state_id: null,
        is_public: false,
        state_registration_date: null,
        state_registration_number: null,
        registration_authority: null,
        registration_certificate: null,
        postal_code: null,
        city: null,
        address: null,
        temporary_stay: null,
        mfo: null,
        bank: null,
        iban: null,
        is_terrorist: false,
        has_sanctions: false,
        country_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      types: "dictionary/allCounterpartTypes",
      legalForms: "dictionary/allCounterpartLegalForms",
      documentTypes: "dictionary/allCounterpartDocumentTypes",
      states: "dictionary/allCounterpartStates",
      statuses: "dictionary/allCounterpartStatuses",
      propertyForms: "dictionary/allCounterpartPropertyForms",
      financialModels: "financialModels/getAllFinancialModels",
      countryList: "dictionary/allCountries",
    }),
  },
  watch: {
    counterpart: {
      deep: true,
      handler(value) {
        this.form.id = value.id;
        this.form.status_id = value.status ? value.status.id : null;
        this.form.type = value.type.id;
        this.form.financial_model_id = value.financial_model
          ? value.financial_model.id
          : null;
        this.form.legal_form_id = value.legal_form ? value.legal_form.id : null;
        this.form.name = value.name;
        this.form.full_legal_name = value.full_legal_name;
        this.form.short_legal_name = value.short_legal_name;
        this.form.last_name = value.last_name;
        this.form.first_name = value.first_name;
        this.form.middle_name = value.middle_name;
        this.form.property_form_id = value.property_form
          ? value.property_form.id
          : null;
        this.form.usreou_code = value.usreou_code;
        this.form.non_resident_code = value.non_resident_code;
        this.form.itn_code = value.itn_code;
        this.form.phone = value.phone;
        this.form.email = value.email;
        this.form.state_id = value.state ? value.state.id : null;
        this.form.is_public = value.is_public;
        this.form.state_registration_date = value.state_registration_date;
        this.form.state_registration_number = value.state_registration_number;
        this.form.registration_authority = value.registration_authority;
        this.form.registration_certificate = value.registration_certificate;
        this.form.postal_code = value.postal_code;
        this.form.city = value.city;
        this.form.address = value.address;
        this.form.temporary_stay = value.temporary_stay;
        this.form.mfo = value.mfo;
        this.form.bank = value.bank;
        this.form.iban = value.iban;
        this.form.is_terrorist = value.is_terrorist;
        this.form.has_sanctions = value.has_sanctions;
        this.form.country_id = value.country ? value.country.id : null;

        requestAnimationFrame(() => {
          this.$refs.formValidation.reset();
        });
      },
    },
  },
  methods: {
    getChangedDocumentFields() {
      if (
        this.isIndividualEntrepreneur(this.counterpart.type) ||
        this.isIndividual(this.counterpart.type) ||
        this.isNonResidentIndividual(this.counterpart.type)
      ) {
        return Object.keys(this.$refs.formValidation.fields).reduce(
          (accumulator, key) => {
            if (
              this.$refs.formValidation.fields[key].changed === true &&
              key in this.$refs.document.form
            ) {
              accumulator.push(key);
            }

            return accumulator;
          },
          []
        );
      } else {
        return [];
      }
    },
    getChangedCounterpartFields() {
      return Object.keys(this.$refs.formValidation.fields).reduce(
        (accumulator, key) => {
          if (
            this.$refs.formValidation.fields[key].changed === true &&
            key in this.form
          ) {
            accumulator.push(key);
          }

          return accumulator;
        },
        []
      );
    },
    getDocumentFormFields(changedDocumentFields) {
      let fields = Object.keys(this.$refs.document.form).reduce(
        (accumulator, key) => {
          if (changedDocumentFields.includes(key) || key === "id") {
            accumulator[key] = this.$refs.document.form[key];
          }

          return accumulator;
        },
        {}
      );
      fields.counterpart_id = this.form.id;

      return fields;
    },
    onSubmit() {
      this.$refs.formValidation.validate().then((success) => {
        if (!success) {
          this.$snotify.warning("Вказано невірний формат поля");

          return;
        }

        let changedDocumentFields = this.getChangedDocumentFields();
        let changedCounterpartFields = this.getChangedCounterpartFields();

        if (changedDocumentFields.length || changedCounterpartFields.length) {
          new Promise((resolve) => {
            if (changedCounterpartFields.length) {
              this.$store
                .dispatch(
                  "counterpart/update",
                  Object.keys(this.form).reduce((accumulator, key) => {
                    if (
                      changedCounterpartFields.includes(key) ||
                      key === "id"
                    ) {
                      accumulator[key] = this.form[key];
                    }

                    return accumulator;
                  }, {})
                )
                .then(() => {
                  if (changedDocumentFields.length <= 0) {
                    this.$snotify.success("Оновлено контрагента");
                  }
                  resolve();
                });
            } else {
              resolve();
            }
          })
            .then(() => {
              if (
                changedDocumentFields.length &&
                this.counterpart.document &&
                this.$refs.document.form.type_id ===
                  this.counterpart.document.type.id
              ) {
                this.$store
                  .dispatch(
                    `counterpart/updateDocument`,
                    this.getDocumentFormFields(changedDocumentFields)
                  )
                  .then(() => {
                    this.$snotify.success("Оновлено контрагента");
                  });
              }
            })
            .then(() => {
              new Promise((resolve) => {
                if (
                  changedDocumentFields.length &&
                  this.counterpart.document &&
                  this.counterpart.document.type.id !==
                    this.$refs.document.form.type_id
                ) {
                  this.$store
                    .dispatch(
                      `counterpart/deleteDocument`,
                      this.counterpart.document.id
                    )
                    .then(() => {
                      resolve();
                    });
                } else {
                  resolve();
                }
              }).then(() => {
                if (
                  !this.counterpart.document &&
                  changedDocumentFields.length
                ) {
                  this.$store
                    .dispatch(
                      `counterpart/createDocument`,
                      this.getDocumentFormFields(changedDocumentFields)
                    )
                    .then(() => {
                      this.$snotify.success("Оновлено контрагента");
                    });
                }
              });
            });
        }
      });
    },
  },
};
</script>
