<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group label-size="lg">
        <ValidationProvider
          name="дата оцінки"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-group label="Дата оцінки" label-class="text-sm">
            <b-form-input
              type="date"
              v-model="form.date_at"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="результат оцінки"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-group label="Результат оцінки">
            <b-form-select
              value-field="id"
              text-field="name"
              :options="activityEvaluations"
              v-model="form.evaluation_result_id"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <b-form-group label="Період оцінки">
          <b-row class="form-group">
            <b-col sm="6">
              <ValidationProvider
                name="початок періоду"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  type="date"
                  v-model="form.start_at"
                  :state="errors.length > 0 ? false : null"
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
                <b-form-text>Початок періоду</b-form-text>
              </ValidationProvider>
            </b-col>

            <b-col sm="6">
              <ValidationProvider
                name="кінець періоду"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  type="date"
                  v-model="form.end_at"
                  :state="errors.length > 0 ? false : null"
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
                <b-form-text>Кінець періоду</b-form-text>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form-group>

      <div slot="footer">
        <b-button
          v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
          type="submit"
          size="sm"
          :variant="index ? 'primary' : 'success'"
        >
          <i class="fa fa-dot-circle-o"></i> {{ index ? "Зберегти" : "Додати" }}
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "activity-evaluation-form",
  props: {
    index: null
  },
  computed: {
    ...mapGetters({
      activityEvaluations: "dictionary/allCounterpartActivityEvaluations"
    }),
    form: function() {
      if (this.index === null)
        return {
          counterpart_id: this.$route.params.id,
          date_at: "",
          start_at: "",
          end_at: "",
          evaluation_result_id: ""
        };
      let form = this.$store.getters["counterpart/getActivityEvaluations"][
        this.index
      ];
      return {
        id: form.id,
        date_at: form.date_at,
        start_at: form.start_at,
        end_at: form.end_at,
        evaluation_result_id: form.evaluation_result.id
      };
    }
  },
  methods: {
    onSubmit() {
      if (this.index === null)
        this.$store.dispatch("counterpart/createActivityEvaluation", this.form);
      else
        this.$store.dispatch("counterpart/updateActivityEvaluation", this.form);
      this.$root.$emit("bv::hide::modal", "modalActivityEvaluation");
    }
  }
};
</script>
